import { Col, Row, Typography } from "antd";
import SectionContainer from "./Container";
import { FaExternalLinkAlt, FaExternalLinkSquareAlt } from "react-icons/fa";
import styled from "styled-components";
import FadeIn from "../components/FadeIn";

const Card = styled.div`
  color: white;

  &:hover {
    background: white;
    color: rgb(157, 34, 53);
    box-shadow: rgb(255, 255, 255, 0.4) 0px 0px 5px 5px;
    transition: 300ms ease-in-out, transform 300ms ease-out;
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const Link = styled.a`
  display: flex;
  color: white !important;
  align-items: center;
  column-gap: 12px;
  white-space: nowrap;
  &:hover {
    color: blue !important;
  }
`;

const Gear = () => {
  return (
    <SectionContainer
      title="Cool Stuff"
      id="cool-stuff"
      bgColor="teal"
      titleColor="white"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 18,
          paddingTop:24,
        }}
      >
        {[
          {
            title: "Jeff Bridges",
            link: "https://www.jeffbridges.com/",
          },
          {
            title: "Jean-Michel Basquiat ",
            link:     "https://www.basquiat.com/"
            ,
          },
          {
            title: "Sox Place",
            link: "https://www.soxplace.com/",
          },
          {
            title: "Drummer World ",
            link:"https://drummerworld.com/"
          },
        ].map(({ title, link }, index) => (
          <FadeIn direction='right'>
            <Card
              onClick={() =>
                window.open(link, "_blank")
              }
              style={{
                alignItems:'center',
                columnGap:8,
                display:'flex'

              }}
            >
              <Typography.Text
                style={{ margin: 0, fontSize: 18, color: "inherit" }}
              >
                {title}
              </Typography.Text>
              <FaExternalLinkAlt size={12} color='teal'/>
            </Card>
          </FadeIn>
        ))}
      </div>
    </SectionContainer>
  );
};

export default Gear;
