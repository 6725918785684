import { Typography } from "antd";
import Link from "antd/es/typography/Link";
import { useEffect, useState } from "react";
import { FaArrowLeft, FaInstagram } from "react-icons/fa";
import "./App.css";
import Drums from "./components/drums/Drums";
import FadeIn from "./components/FadeIn";
import ShopifyBanner from "./components/Shopify";
import Bio from "./sections/Bio";
import Contact from "./sections/Contact";
import CoolStuff from "./sections/CoolStuff";
import Credits from "./sections/Credits";
import Gear from "./sections/Gear";
import Media from "./sections/Media";

function App() {
  const [selected, setSelected] = useState<string>();

  return (
    <>
      {/* <ShopifyBanner />x */}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#121212",
          justifyContent: "space-between",
          // width: "calc(100% )",
          marginLeft: -10,
          padding: "10px 20px",
          paddingTop: 20,
          paddingBottom: 15,
          marginTop: -10,
          alignItems: "center",
          paddingRight: 20,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <FadeIn direction="top">
            <Typography.Title
              ellipsis
              level={3}
              style={{
                color: "white",
                margin: 0,
                letterSpacing: 4,
              }}
            >
              EVAN
            </Typography.Title>
          </FadeIn>
          <FadeIn direction="right">
            <Typography.Title
              ellipsis
              level={3}
              style={{
                color: "teal",
                margin: 0,
                paddingLeft: 5,
                letterSpacing: 4,
              }}
            >
              HUTCHINGS
            </Typography.Title>
          </FadeIn>
        </div>
        <Link href="https://www.instagram.com/evanhutchings/" target="_blank">
          <FaInstagram color="white" size={24} />
        </Link>
      </div>

      <ShopifyBanner />

      <Link
        href="/"
        style={{
          color: "teal",
          display: selected ? "flex" : "none",
          alignItems: "center",
          columnGap: 5,
          paddingLeft: 12,
          paddingTop: 24,
        }}
      >
        <FaArrowLeft />
        <Typography.Text strong style={{ fontSize: 15, color: "teal" }}>
          Back
        </Typography.Text>
      </Link>

      <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
        {selected ? null : <Drums selected={selected} onSelect={setSelected} />}
        {selected === "bio" && <Bio />}
        {selected === "credits" && <Credits />}
        {selected === "media" && <Media />}
        {selected === "gear" && <Gear />}
        {selected === "cool-stuff" && <CoolStuff />}
        {selected === "contact" && <Contact />}
      </div>
      {selected ? null : (
        <FadeIn direction="bottom">
        <div
          style={{
            marginLeft:-10,
            backgroundColor: "#ffffffAC",
            justifyContent: "center",
            gap: 60,
            alignItems: "center",
            display: "flex",
            marginTop: 100,
            padding: "10px 20px",
          }}
        >
          <img
            src="./logos/kwat.png"
            height={44}
            onClick={() => window.open("https://www.k-watdrumco.com",'_blank')}
            width={58}
            style={{ objectFit: "contain", cursor:'pointer' }}
          />
          <img
            src="./logos/zildjian.png"
            height={40}
            width={120}
            style={{ objectFit: "contain", cursor:'pointer' }}
            onClick={() => window.open("https://www.zildjian.com",'_blank')}
          />
          <img
            src="./logos/vic-firth.png"
            height={40}
            width={120}
            onClick={() => window.open("https://www.vicfirth.com",'_blank')}
            style={{ marginBottom:10, objectFit: "contain", cursor:'pointer' }}
          />
                   <img
            src="./logos/evans.png"
            height={40}
            width={120}
            onClick={() => window.open("https://www.daddario.com/products/percussion/evans-drumheads/drum-set/drumset-snare-batter",'_blank')}
            style={{ objectFit: "contain", cursor:'pointer' }}
          />

          
        </div>
        </FadeIn>
        
      )}
      {/* </div> */}
      {/* <ScrollToTopButton /> */}
    </>
  );
}

export default App;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};
