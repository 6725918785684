import { Col, Row, Typography } from "antd";
import Link from "antd/es/typography/Link";

const ShopifyBanner = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        alignItems: "center",
        width:'100%',
        marginLeft:-10,
        paddingLeft:10,
        paddingTop:4,
        display:'flex',
        justifyContent: "center",
        // marginLeft:-20,
        // width:'calc(100% + 30px)'
      }}
    >
      <Link
        style={{
          color: "black",
          textTransform: "uppercase",
          fontSize: 18,
          fontWeight: "700",
          textAlign: "center",
          fontFamily: "Josefin Sans",
        }}
        target="_blank"
        href="https://essentialdrumsamples.myshopify.com/"
      >
        Click here for drum samples!
      </Link>
    </div>
  );
};

export default ShopifyBanner;
