import { Button, Col, Form, Input, message, Row, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import FadeIn from "../components/FadeIn";
import SectionContainer from "./Container";
import emailjs from "@emailjs/browser";
import { useEffect, useRef, useState } from "react";
import { useWatch } from "antd/es/form/Form";

const Gear = () => {
  const [form] = Form.useForm();
  const [values, setValues] = useState({
    first: "",
    last: "",
    email: "",
    phone: "",
    message: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [submittable, setSubmittable] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const sendEmail = (e: any) => {
    e.preventDefault();
    setSubmitting(true);
    emailjs
      .send(
        "service_qnggb2x",
        "template_u2v9rho",
        {
          ...form.getFieldsValue(),
        },
        {
          publicKey: "ZWYFXJKuxNfBGOcDn",
        }
      )
      .then(
        () => {
          messageApi.open({
            type: "success",

            content: "Message sent! Thanks for reaching out",
          });

          form.resetFields();
        },
        (error) => {
          console.log(error);
          messageApi.open({
            type: "error",
            content: "Message failed to send! Please try again",
          });
        }
      )
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    setSubmittable(
      Object.values(values).every((value) => value !== "")
    );
  }, [values]);

  return (
    <SectionContainer
      title="Contact"
      id="contact"
      bgColor="white"
      titleColor="teal"
    >
      {contextHolder}

      <FadeIn direction="bottom">
        <div style={{ display: "flex" }}>
          <Form
            form={form}
            layout="vertical"
            style={{
              flex: 1,
              maxWidth: 400,
              background: "#222222",
              padding: 20,
              borderRadius: 5,
            }}
          >
            <Form.Item
              name="first"
              label={<span style={{ color: "white" }}>First Name</span>}
              required
            >
              <Input
                onChange={({ target: { value: first } }) =>
                  setValues((values) => ({ ...values, first }))
                }
              />
            </Form.Item>
            <Form.Item
              name="last"
              validateTrigger="onBlur"
              label={<span style={{ color: "white" }}>Last Name</span>}
              required
            >
              <Input
                onChange={({ target: { value: last } }) =>
                  setValues((values) => ({ ...values, last }))
                }
              />
            </Form.Item>
            <Form.Item
              name="phone"
              validateTrigger="onBlur"
              label={<span style={{ color: "white" }}>Phone Number</span>}
              required
            >
              <Input
                onChange={({ target: { value: phone } }) =>
                  setValues((values) => ({ ...values, phone }))
                }
              />
            </Form.Item>
            <Form.Item
              name="email"
              validateTrigger="onBlur"
              label={<span style={{ color: "white" }}>Email</span>}
              required
            >
              <Input
                onChange={({ target: { value: email } }) =>
                  setValues((values) => ({ ...values, email }))
                }
              />
            </Form.Item>
            <Form.Item
              name="message"
              validateTrigger="onBlur"
              label={<span style={{ color: "white" }}>Message</span>}
              required
            >
              <Input          onChange={({ target: { value: message } }) =>
                  setValues((values) => ({ ...values, message }))
                }/>
            </Form.Item>
            <FadeIn direction="bottom">
              <Form.Item>
                <Button
                  onClick={sendEmail}
                  type="default"
                  disabled={!submittable || submitting}
                  style={{
                    // position: "fixed",
                    width: "100%",
                    border: "none",
                    backgroundColor: "teal",
                    boxShadow: "rgb(0, 0, 0, 0.4) 0px 2px 10px 1px",
                    color: "white",
                    padding: "20px 10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: !submittable || submitting ? 0.5 : 1,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Submit
                  </span>
                </Button>
              </Form.Item>
            </FadeIn>
          </Form>
        </div>
      </FadeIn>
    </SectionContainer>
  );
};

export default Gear;
