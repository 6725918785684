import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FadeIn from "../FadeIn";

const Navigation = styled.ul<{ selectedIndex: number | null }>`
  color: white;
  font-weight: 800;
  font-size: 18px;
  line-height: 1.8em;
  padding: 0px;
  margin: 12px 0px 0px 12px;

  li {
    list-style-type: none;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    padding: 4px 8px;
    white-space: nowrap;
  }

  li:nth-child(${({ selectedIndex }) => selectedIndex}) {
    color: teal;
  }
`;

const KitPiece = styled.img<{ selected: boolean; hovered: boolean }>`
  transition: all 0.4s ease-in-out;
  max-height: 500px;
  &:hover {
    cursor: pointer;
  }
  ${({ selected, hovered }) =>
    selected
      ? `
    filter: drop-shadow(3px 6px 12px teal);
    transform: scale(1.1);
  `
      : hovered
      ? `    filter: drop-shadow(3px 6px 12px white);
        transform: scale(1.1);`
      : ""}
`;

const Drums: React.FC<{
  selected?: string;
  onSelect: (selected: string) => void;
}> = ({ onSelect, selected }) => {
  const [hovered, setHovered] = useState<string>();
  const ref = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(0);

  const updateDimensions = () => {
    if (ref.current) {
      setScale(
        ref.current?.clientWidth ? ref?.current?.clientWidth / 2000 : 0.2
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener("resize", updateDimensions);
      setScale(
        ref.current?.clientWidth ? ref?.current?.clientWidth / 2000 : 0.2
      );
      return () => {
        window.removeEventListener("resize", updateDimensions);
      };
    }, 100);
  }, [ref.current?.clientWidth]);

  useEffect(() => {
    if (selected) {
      setTimeout(() => {
        document.getElementById(selected)?.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    }
  }, [selected]);

  return (
    <FadeIn direction="bottom">
      <div
        ref={ref}
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 175 * scale,
          flex: 1,
          maxWidth: 1200,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <KitPiece
          onClick={() => {
            onSelect("bio");
          }}
          onMouseEnter={() => setHovered("bio")}
          onMouseLeave={() => setHovered(undefined)}
          selected={selected === "bio"}
          hovered={hovered === "bio"}
          style={{
            width: "100%",
            height: "100%",
            maxWidth: 550 * scale,
            maxHeight: 780 * scale,
            marginRight: -500 * scale,
            marginTop: 260 * scale,
            zIndex: 1,
          }}
          src={
            hovered === "bio" || selected === "bio"
              ? "./drums/hihat-color.png"
              : "./drums/hihat-bw.png"
          }
        />
        <KitPiece
          onClick={() => onSelect("credits")}
          onMouseEnter={() => setHovered("credits")}
          onMouseLeave={() => setHovered(undefined)}
          selected={selected === "credits"}
          hovered={hovered === "credits"}
          style={{
            width: "100%",
            height: "100%",
            maxWidth: 623 * scale,
            maxHeight: 764 * scale,
            marginTop: -50 * scale,
            marginRight: -50 * scale,
          }}
          src={
            hovered === "credits" || selected === "credits"
              ? "./drums/crash1-color.png"
              : "./drums/crash1-bw.png"
          }
        />
        <KitPiece
          onClick={() => onSelect("media")}
          onMouseEnter={() => setHovered("media")}
          onMouseLeave={() => setHovered(undefined)}
          selected={selected === "media"}
          hovered={hovered === "media"}
          style={{
            width: "100%",
            height: "100%",
            // position: "absolute",
            // left: 0,
            maxWidth: 292 * scale,
            maxHeight: 292 * scale,
            marginRight: 175 * scale,
            marginLeft: -205 * scale,
            marginTop: 200 * scale,
            zIndex: 1,
          }}
          src="./drums/rack-tom.png"
        />

        <KitPiece
          onClick={() => onSelect("media")}
          onMouseEnter={() => setHovered("media")}
          onMouseLeave={() => setHovered(undefined)}
          selected={selected === "media"}
          hovered={hovered === "media"}
          style={{
            width: "100%",
            height: "100%",
            maxWidth: 350 * scale,
            maxHeight: 500 * scale,
            marginLeft: -460 * scale,
            marginTop: 440 * scale,
            zIndex: 2,
          }}
          src={
            hovered === "media" || selected === "media"
              ? "./drums/snare-color.png"
              : "./drums/snare-bw.png"
          }
        />

        <KitPiece
          onClick={() => onSelect("gear")}
          onMouseEnter={() => setHovered("gear")}
          onMouseLeave={() => setHovered(undefined)}
          selected={selected === "gear"}
          hovered={hovered === "gear"}
          style={{
            width: "100%",
            height: "100%",
            maxWidth: 337 * scale,
            maxHeight: 588 * scale,
            marginLeft: -159 * scale,
            marginRight: -175 * scale,
            marginTop: 330 * scale,
          }}
          src="./drums/kick.png"
        />

        <KitPiece
          onClick={() => onSelect("gear")}
          onMouseEnter={() => setHovered("gear")}
          onMouseLeave={() => setHovered(undefined)}
          selected={selected === "gear"}
          hovered={hovered === "gear"}
          style={{
            width: "100%",
            height: "100%",
            maxWidth: 403 * scale,
            maxHeight: 427 * scale,
            marginLeft: -16 * scale,
            marginTop: 90 * scale,
          }}
          src={
            hovered === "gear" || selected === "gear"
              ? "./drums/ride-color.png"
              : "./drums/ride-bw.png"
          }
        />
        <KitPiece
          onClick={() => onSelect("cool-stuff")}
          onMouseEnter={() => setHovered("cool-stuff")}
          onMouseLeave={() => setHovered(undefined)}
          selected={selected === "cool-stuff"}
          hovered={hovered === "cool-stuff"}
          style={{
            width: "100%",
            height: "100%",
            maxWidth: 444 * scale,
            maxHeight: 504 * scale,
            marginLeft: -310 * scale,
            marginTop: 450 * scale,
            zIndex: 1,
          }}
          src={
            hovered === "cool-stuff" || selected === "cool-stuff"
              ? "./drums/floor-tom-color.png"
              : "./drums/floor-tom-bw.png"
          }
        />

        <KitPiece
          onClick={() => onSelect("contact")}
          onMouseEnter={() => setHovered("contact")}
          onMouseLeave={() => setHovered(undefined)}
          selected={selected === "contact"}
          hovered={hovered === "contact"}
          style={{
            width: "100%",
            height: "100%",
            maxWidth: 726 * scale,
            maxHeight: 729 * scale,
            marginLeft: -245 * scale,
          }}
          src={
            hovered === "contact" || selected === "contact"
              ? "./drums/crash2-color.png"
              : "./drums/crash2-bw.png"
          }
        />
      </div>
    </FadeIn>
  );
};
export default Drums;
