import { Col, Divider, Row, Typography } from "antd";
import FadeIn from "../components/FadeIn";
import SectionContainer from "./Container";

const Media = () => {
  return (
    <SectionContainer
      title="Media"
      id="media"
      bgColor="teal"
      titleColor="white"
    >
      <FadeIn direction="bottom">
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            marginBottom: 24,
            gap: 12,
          }}
        >
          {[
            "https://www.youtube.com/embed/WCFadbUxvcY",
            "https://www.youtube.com/embed/T9y00dwn0ys",
          ].map((link) => (
            <iframe
              frameBorder={0}
              height={400}
              style={{ flex: 1 }}
              src={link}
            />
          ))}
        </div>
      </FadeIn>
    </SectionContainer>
  );
};

export default Media;
