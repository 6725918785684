import { Col, Row, Typography } from "antd";
import { PropsWithChildren } from "react";
import FadeIn from "../components/FadeIn";

const SectionContainer: React.FC<
  PropsWithChildren<{
    id: string;
    title?: string;
    bgColor: string;
    titleColor: string;
  }>
> = ({ id, bgColor, titleColor, title, children }) => {
  return (
    <Row id={id}>
      <Col
        style={{
          borderRadius: 5,
          padding: 12,
          // boxShadow: "rgb(200, 255, 255, 0.4) 0px 0px 5px 5px",
        }}
        xs={{ span: 24 }}
      >
        <div style={{}}>
          <FadeIn direction="top">
            <Typography.Title
              level={1}
              style={{
                display: title ? "flex" : "none",
                color: "white",
                textShadow: "1px 3px #33333380",
                //   fontSize: 50,
                textAlign: "center",
                alignSelf: "center",
                fontSize: 30,
              }}
            >
              {title}
            </Typography.Title>
          </FadeIn>
          {children}
        </div>
      </Col>
    </Row>
  );
};

export default SectionContainer;
