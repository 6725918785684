import { Col, Row, Typography } from "antd";
import styled from "styled-components";
import FadeIn from "../components/FadeIn";
import SectionContainer from "./Container";

const ImageContainer = styled.div`
  padding: 10px;
  border-radius: 5px;
  display:flex;
  flex: 1;
  align-items:center;
  justify-content:center;
  align-self:center; 

  &:hover {
    transition: 300ms ease-in-out, transform 300ms ease-out; 
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const Gear = () => {
  return (
    <SectionContainer title="Gear" id="gear" bgColor="white" titleColor="teal">
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexDirection:'row',
          flexWrap: "wrap",
          gap: 24,
        }}
      >
        <FadeIn direction="left">
          <ImageContainer>

          <img
            onClick={() => window.open('https://zildjian.com/blogs/artist/evan-hutchings', '_blank')}
            src="./logos/zildjian.png"
            height={60}
          />
          </ImageContainer>
        </FadeIn>
        <FadeIn direction="right">
          <ImageContainer>

          <img
                      onClick={() => window.open('https://vicfirth.zildjian.com/evan-hutchings-vf.html', '_blank')}
            src="./logos/vic-firth.png"
            height={60}
          />
          </ImageContainer>
        </FadeIn>
        <FadeIn direction="left">
          <ImageContainer>

          <img
                      onClick={() => window.open('https://www.daddario.com/products/percussion/evans-drumheads/drum-set/drumset-snare-batter/', '_blank')}
            src="./logos/evans.png"
            height={60}
          />
          </ImageContainer>
        </FadeIn>
        <FadeIn direction="right">
          <ImageContainer>

          <img
                      onClick={() => window.open('https://www.k-watdrumco.com', '_blank')}
            src="./logos/kwat.png"
            height={70}
          />
          </ImageContainer>
        </FadeIn>
      </div>
    </SectionContainer>
  );
};

export default Gear;
