import React, { useRef, useEffect, useState, PropsWithChildren } from "react";
import styled, { css, keyframes } from "styled-components";

type Direction = "left" | "right" | "top" | "bottom";

interface FadeInProps extends PropsWithChildren<any> {
  direction?: Direction;
}

const FadeIn = ({ children, direction = "bottom" }: FadeInProps) => {
  const ref = useRef(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Wrapper
      ref={ref}
      direction={direction}
      className={isVisible ? "fade-in" : ""}
    >
      {children}
    </Wrapper>
  );
};

const animation = ({ direction = "left" }) => {
  const animation = keyframes`
    0% {
      ${direction}: -10%; 
      opacity: 0;
    }

    100% { 
      ${direction}: 0%;
      opacity: 0.5;
    }

    100% { 
      ${direction}: 0%;
      opacity: 1;
    }
  `;
    
  return css`
    position: relative;
    width:100%;
    animation: ${animation} 1s ease-in-out;
    animation-fill-mode: forwards;
  `;
};

const Wrapper = styled.div<{ direction: Direction }>`
opacity:0;
  transition: opacity 300ms ease-in-out, transform 300ms ease-out;

  &.fade-in {
    ${animation};
  }
`;

const getInitialTransform = (direction: Direction) => {
  switch (direction) {
    case "left":
      return "translateX(-100%)";
    case "right":
      return "translateX(100%)";
    case "top":
      return "translateY(-100%)";
    case "bottom":
    default:
      return "translateY(100%)";
  }
};

export default FadeIn;
