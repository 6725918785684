import { Col, Row, Typography } from "antd";
import FadeIn from "../components/FadeIn";
import SectionContainer from "./Container";

const Bio = () => {
  return (
    <SectionContainer id="bio" bgColor="teal" titleColor="white">
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          gap: 24,
        }}
      >
        <FadeIn direction="right">
          <div style={{ display: "flex", flexDirection: "row", rowGap: 24 }}>
            <div
              style={{
                padding: 24,
                flex: 1,
                zIndex: 1,
                minWidth: 300,
                maxWidth: 500,
                height: "max-content",
                borderRadius: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom:24,
                }}
              >
                <img
                  src="./evan/evan.png"
                  // width='50%'
                  style={{
                    // border: "3px solid rgb(157, 34, 53)",
                    // width: 250,
                    width: "75%",
                    alignSelf: "center",
                    height: "auto",
                    borderRadius: "100%",
                    boxShadow: "rgb(255, 255, 255, 0.15) 0px 0px 5px 5px",
                  }}
                />

              </div>
              
              <Typography.Text style={{ fontSize: 16, color: "white" }}>

                <span style={{ width: 24, display: "inline-block" }} />
                <span style={{ textAlign: "justify" }} />
                Born and raised in Arkansas, Evan grew up playing drums at his
                  dad’s church in Fort Smith. He was the kid that would sit
                  behind the drummer during church and soak in every detail and
                  nuance. Once church let out, he’d jump up on the kit and play,
                  until the next church service started a few hours later.
                <br />
                <br/>
                <span style={{ width: 24, display: "inline-block" }} />
                <span style={{ textAlign: "justify" }} />
                Evan got his first drum set at the age of 8 and began serenading
                his whole family and neighborhood with punk rock beats and the
                sounds of the radio. After touring the country with his band The
                Exception, Evan decided it was time to move to Nashville, TN and
                attend Middle Tennessee State under the direction of Lalo Davila
                and Tom Giampietro. It was in college where Evan honed his craft
                and wanted to become a studio musician.
                <br />
                <br />
                <span style={{ width: 24, display: "inline-block" }} />
                Years later and having played on multiple #1 songs on the radio,
                Evan is now an in demand studio drummer. You can hear his
                drumming with , Kenny Chesney, Reba McEntire, Luke Bryan, Jimmie
                Allen, Sam Hunt, Ronnie Dunn, Keith Urban, Steven Tyler, Toby
                Keith, Matt Stell, Maddie and Tae, Tim McGraw, Lauren Alaina,
                Erin McCarley, Rascal Flatts, Parker
              </Typography.Text>
            </div>
          </div>
        </FadeIn>
      </div>
    </SectionContainer>
  );
};

export default Bio;
