const credits = [
    {
     year: 2022,
     album: "Tulip Drive",
     albumLink: "https://www.allmusic.com/album/tulip-drive-mw0003721955",
     artist: "Jimmie Allen",
     artistLink: "https://www.allmusic.com/artist/jimmie-allen-mn0003672595",
     contributions: "Drum Programming, Drums, Engineer, Percussion, Programmer"
    },
    {
     year: 2022,
     album: " ",
     albumLink: "https://www.allmusic.com/album/through-the-madness-vol-2-mw0003840141",
     artist: "Maddie &amp; Tae",
     artistLink: "https://www.allmusic.com/artist/maddie-tae-mn0003282505",
     contributions: "Drums"
    },
    {
     year: 2022,
     album: "Through the Madness, Vol. 1",
     albumLink: "https://www.allmusic.com/album/through-the-madness-vol-1-mw0003661805",
     artist: "Maddie &amp; Tae",
     artistLink: "https://www.allmusic.com/artist/maddie-tae-mn0003282505",
     contributions: "Drums"
    },
    {
     year: 2022,
     album: "The Ultimate Christmas Collection",
     albumLink: "https://www.allmusic.com/album/the-ultimate-christmas-collection-mw0003798237",
     artist: "Reba McEntire",
     artistLink: "https://www.allmusic.com/artist/reba-mcentire-mn0000409968",
     contributions: "Drum, Drums"
    },
    {
     year: 2022,
     album: "The Last Resort: Greetings From",
     albumLink: "https://www.allmusic.com/album/the-last-resort-greetings-from-mw0003696189",
     artist: "Midland",
     artistLink: "https://www.allmusic.com/artist/midland-mn0003567510",
     contributions: "Programming"
    },
    {
     year: 2022,
     album: "Subject to Change",
     albumLink: "https://www.allmusic.com/album/subject-to-change-mw0003763573",
     artist: "Kelsea Ballerini",
     artistLink: "https://www.allmusic.com/artist/kelsea-ballerini-mn0003312551",
     contributions: "Drums, Percussion, Programming"
    },
    {
     year: 2022,
     album: "Prayin' in a Deer Stand",
     albumLink: "https://www.allmusic.com/album/prayin-in-a-deer-stand-mw0003902923",
     artist: "Luke Bryan",
     artistLink: "https://www.allmusic.com/artist/luke-bryan-mn0000502198",
     contributions: "Drums, Tambourine"
    },
    {
     year: 2022,
     album: "P.S.",
     albumLink: "https://www.allmusic.com/album/ps-mw0003770598",
     artist: "Kylie Morgan",
     artistLink: "https://www.allmusic.com/artist/kylie-morgan-mn0003368858",
     contributions: "Drums, Percussion"
    },
    {
     year: 2022,
     album: "On My Way",
     albumLink: "https://www.allmusic.com/album/on-my-way-mw0003721964",
     artist: "Jimmie Allen / Jennifer Lopez",
     contributions: "Drum Programming"
    },
    {
     year: 2022,
     album: "Neon Blue",
     albumLink: "https://www.allmusic.com/album/neon-blue-mw0003664811",
     artist: "Joshua Hedley",
     artistLink: "https://www.allmusic.com/artist/joshua-hedley-mn0002550149",
     contributions: "Drums, Percussion"
    },
    {
     year: 2022,
     album: "Lovin’ You on My Mind",
     albumLink: "https://www.allmusic.com/album/lovin-you-on-my-mind-mw0003674214",
     artist: "Josh Turner",
     artistLink: "https://www.allmusic.com/artist/josh-turner-mn0000277764",
     contributions: "Drums, Percussion, Recording"
    },
    {
     year: 2022,
     album: "God. Family. Country.",
     albumLink: "https://www.allmusic.com/album/god-family-country-mw0003735442",
     artist: "Josh Turner",
     artistLink: "https://www.allmusic.com/artist/josh-turner-mn0000277764",
     contributions: "Drums, Percussion, Recording"
    },
    {
     year: 2022,
     album: "Cross Country",
     albumLink: "https://www.allmusic.com/album/cross-country-mw0003792474",
     artist: "Breland",
     artistLink: "https://www.allmusic.com/artist/breland-mn0003901306",
     contributions: "Drums, Engineer, Percussion"
    },
    {
     year: 2022,
     album: "Country Stuff the Album",
     albumLink: "https://www.allmusic.com/album/country-stuff-the-album-mw0003626502",
     artist: "Walker Hayes",
     artistLink: "https://www.allmusic.com/artist/walker-hayes-mn0002566358",
     contributions: "Drums, Percussion"
    },
    {
     year: 2022,
     album: "Country On",
     albumLink: "https://www.allmusic.com/album/country-on-mw0003763456",
     artist: "Luke Bryan",
     artistLink: "https://www.allmusic.com/artist/luke-bryan-mn0000502198",
     contributions: "Drums, Tambourine"
    },
    {
     year: 2022,
     album: "All In",
     albumLink: "https://www.allmusic.com/album/all-in-mw0003683837",
     artist: "Chris Janson",
     artistLink: "https://www.allmusic.com/artist/chris-janson-mn0001069933",
     contributions: "Drums, Percussion"
    },
    {
     year: 2021,
     album: "You Get It All",
     albumLink: "https://www.allmusic.com/album/you-get-it-all-mw0003566092",
     artist: "Hayes Carll",
     artistLink: "https://www.allmusic.com/artist/hayes-carll-mn0000562105",
     contributions: "Drums"
    },
    {
     year: 2021,
     album: "Wild Hearts",
     albumLink: "https://www.allmusic.com/album/wild-hearts-mw0003583950",
     artist: "Keith Urban",
     artistLink: "https://www.allmusic.com/artist/keith-urban-mn0000081619",
     contributions: "Drums"
    },
    {
     year: 2021,
     album: "The Last Resort",
     albumLink: "https://www.allmusic.com/album/the-last-resort-mw0003559022",
     artist: "Midland",
     artistLink: "https://www.allmusic.com/artist/midland-mn0003567510",
     contributions: "Programming"
    },
    {
     year: 2021,
     album: "The Comeback",
     albumLink: "https://www.allmusic.com/album/the-comeback-mw0003586959",
     artist: "Zac Brown / Zac Brown Band",
     contributions: "Drums"
    },
    {
     year: 2021,
     album: "Straight Outta the Country",
     albumLink: "https://www.allmusic.com/album/straight-outta-the-country-mw0003522076",
     artist: "Justin Moore",
     artistLink: "https://www.allmusic.com/artist/justin-moore-mn0000864411",
     contributions: "Drums"
    },
    {
     year: 2021,
     album: "Remember Her Name",
     albumLink: "https://www.allmusic.com/album/remember-her-name-mw0003557451",
     artist: "Mickey Guyton",
     artistLink: "https://www.allmusic.com/artist/mickey-guyton-mn0003237096",
     contributions: "Drums, Percussion"
    },
    {
     year: 2021,
     album: "Reba: Revived Remixed Revisited",
     albumLink: "https://www.allmusic.com/album/reba-revived-remixed-revisited-mw0003575298",
     artist: "Reba McEntire",
     artistLink: "https://www.allmusic.com/artist/reba-mcentire-mn0000409968",
     contributions: "Drums, Percussion"
    },
    {
     year: 2021,
     album: "Quiet Revolution",
     albumLink: "https://www.allmusic.com/album/quiet-revolution-mw0003482499",
     artist: "Truman Brothers",
     artistLink: "https://www.allmusic.com/artist/truman-brothers-mn0004023941",
     contributions: "Drums, Percussion"
    },
    {
     year: 2021,
     album: "Living in Colour",
     albumLink: "https://www.allmusic.com/album/living-in-colour-mw0003576317",
     artist: "Shane Nicholson",
     artistLink: "https://www.allmusic.com/artist/shane-nicholson-mn0000163963",
     contributions: "Drums"
    },
    {
     year: 2021,
     album: "King Size Manger",
     albumLink: "https://www.allmusic.com/album/king-size-manger-mw0003583315",
     artist: "Josh Turner",
     artistLink: "https://www.allmusic.com/artist/josh-turner-mn0000277764",
     contributions: "Drums, Percussion"
    },
    {
     year: 2021,
     album: "Kids",
     albumLink: "https://www.allmusic.com/album/kids-mw0003561219",
     artist: "Sam Williams",
     artistLink: "https://www.allmusic.com/artist/sam-williams-mn0003339197",
     contributions: "Drums"
    },
    {
     year: 2021,
     album: "I'll Be There",
     albumLink: "https://www.allmusic.com/album/ill-be-there-mw0003550166",
     artist: "Josh Turner",
     artistLink: "https://www.allmusic.com/artist/josh-turner-mn0000277764",
     contributions: "Drums"
    },
    {
     year: 2021,
     album: "Have Yourself a Merry Little Christmas",
     albumLink: "https://www.allmusic.com/album/have-yourself-a-merry-little-christmas-mw0003597930",
     artist: "The Turner Family / Josh Turner",
     contributions: "Drums"
    },
    {
     year: 2021,
     album: "Gold Chain Cowboy",
     albumLink: "https://www.allmusic.com/album/gold-chain-cowboy-mw0003548582",
     artist: "Parker McCollum",
     artistLink: "https://www.allmusic.com/artist/parker-mccollum-mn0003377460",
     contributions: "Drums, Percussion"
    },
    {
     year: 2021,
     album: "For You",
     albumLink: "https://www.allmusic.com/album/for-you-mw0003547258",
     artist: "Parmalee",
     artistLink: "https://www.allmusic.com/artist/parmalee-mn0000130987",
     contributions: "Drums, Percussion"
    },
    {
     year: 2021,
     album: "Fire",
     albumLink: "https://www.allmusic.com/album/fire-mw0003581469",
     artist: "Calee Reed",
     artistLink: "https://www.allmusic.com/artist/calee-reed-mn0002929619",
     contributions: "Drums"
    },
    {
     year: 2021,
     album: "Dirt Road Down",
     albumLink: "https://www.allmusic.com/album/dirt-road-down-mw0003554620",
     artist: "Travis Denning",
     artistLink: "https://www.allmusic.com/artist/travis-denning-mn0002996272",
     contributions: "Drums"
    },
    {
     year: 2021,
     album: "29: Written in Stone",
     albumLink: "https://www.allmusic.com/album/29-written-in-stone-mw0003569504",
     artist: "Carly Pearce",
     artistLink: "https://www.allmusic.com/artist/carly-pearce-mn0000585748",
     contributions: "Drums, Engineer, Percussion, Recording"
    },
    {
     year: 2021,
     album: 29,
     albumLink: "https://www.allmusic.com/album/29-mw0003486008",
     artist: "Carly Pearce",
     artistLink: "https://www.allmusic.com/artist/carly-pearce-mn0000585748",
     contributions: "Drums, Percussion, Recording"
    },
    {
     year: 2020,
     album: "Work for Shoes",
     albumLink: "https://www.allmusic.com/album/work-for-shoes-mw0003391962",
     artist: "Stephanie Owens",
     artistLink: "https://www.allmusic.com/artist/stephanie-owens-mn0003722058",
     contributions: "Drums"
    },
    {
     year: 2020,
     album: "We Need Christmas",
     albumLink: "https://www.allmusic.com/album/we-need-christmas-mw0003430041",
     artist: "Maddie &amp; Tae",
     artistLink: "https://www.allmusic.com/artist/maddie-tae-mn0003282505",
     contributions: "Drums"
    },
    {
     year: 2020,
     album: "The Way It Feels",
     albumLink: "https://www.allmusic.com/album/the-way-it-feels-mw0003355324",
     artist: "Maddie &amp; Tae",
     artistLink: "https://www.allmusic.com/artist/maddie-tae-mn0003282505",
     contributions: "Drums"
    },
    {
     year: 2020,
     album: "The Speed of Now, Vol. 1",
     albumLink: "https://www.allmusic.com/album/the-speed-of-now-vol-1-mw0003400496",
     artist: "Keith Urban",
     artistLink: "https://www.allmusic.com/artist/keith-urban-mn0000081619",
     contributions: "Drums"
    },
    {
     year: 2020,
     album: "The Singin’ Hills Sessions: Mojave",
     albumLink: "https://www.allmusic.com/album/the-singin-hills-sessions-mojave-mw0003433830",
     artist: "Billy Ray Cyrus",
     artistLink: "https://www.allmusic.com/artist/billy-ray-cyrus-mn0000076561",
     contributions: "Drums"
    },
    {
     year: 2020,
     album: "Step Into My Story",
     albumLink: "https://www.allmusic.com/album/step-into-my-story-mw0003453290",
     artist: "Selah",
     artistLink: "https://www.allmusic.com/artist/selah-mn0000004685",
     contributions: "Drums"
    },
    {
     year: 2020,
     album: "Southside",
     albumLink: "https://www.allmusic.com/album/southside-mw0003356449",
     artist: "Sam Hunt",
     artistLink: "https://www.allmusic.com/artist/sam-hunt-mn0002674420",
     contributions: "Drums"
    },
    {
     year: 2020,
     album: "Soundtrack to Summer 2020",
     albumLink: "https://www.allmusic.com/album/soundtrack-to-summer-2020-mw0003390418",
     contributions: "Drums"
    },
    {
     year: 2020,
     album: "Soundtrack to Summer 2020",
     albumLink: "https://www.allmusic.com/album/soundtrack-to-summer-2020-mw0003426856",
     contributions: "Drums"
    },
    {
     year: 2020,
     album: "NOW Country: Songs of Inspiration, Vol. 2",
     albumLink: "https://www.allmusic.com/album/now-country-songs-of-inspiration-vol-2-mw0003354569",
     contributions: "Drums"
    },
    {
     year: 2020,
     album: "Love, Heartbreak &amp; Everything In Between",
     albumLink: "https://www.allmusic.com/album/love-heartbreak-amp-everything-in-between-mw0003341111",
     artist: "Tenille Arts",
     artistLink: "https://www.allmusic.com/artist/tenille-arts-mn0003714371",
     contributions: "Drums, Percussion"
    },
    {
     year: 2020,
     album: "Lady Like",
     albumLink: "https://www.allmusic.com/album/lady-like-mw0003358117",
     artist: "Ingrid Andress",
     artistLink: "https://www.allmusic.com/artist/ingrid-andress-mn0002596016",
     contributions: "Drums"
    },
    {
     year: 2020,
     album: "Kings of the Dairy Queen Parking Lot",
     albumLink: "https://www.allmusic.com/album/kings-of-the-dairy-queen-parking-lot-mw0003428557",
     artist: "Everette",
     artistLink: "https://www.allmusic.com/artist/everette-mn0002161633",
     contributions: "Drums, Percussion"
    },
    {
     year: 2020,
     album: "If I’m Being Honest",
     albumLink: "https://www.allmusic.com/album/if-im-being-honest-mw0003370348",
     artist: "Dan Smalley",
     artistLink: "https://www.allmusic.com/artist/dan-smalley-mn0003921237",
     contributions: "Drums"
    },
    {
     year: 2020,
     album: "Hope: Songs of Love and Strength",
     albumLink: "https://www.allmusic.com/album/hope-songs-of-love-and-strength-mw0003374662",
     contributions: "Drums"
    },
    {
     year: 2020,
     album: "Here and Now",
     albumLink: "https://www.allmusic.com/album/here-and-now-mw0003369896",
     artist: "Kenny Chesney",
     artistLink: "https://www.allmusic.com/artist/kenny-chesney-mn0000068551",
     contributions: "Drums"
    },
    {
     year: 2020,
     album: "Everbound",
     albumLink: "https://www.allmusic.com/album/everbound-mw0003379005",
     artist: "The Washboard Union",
     artistLink: "https://www.allmusic.com/artist/the-washboard-union-mn0002980203",
     contributions: "Drums, Percussion"
    },
    {
     year: 2020,
     album: "Daydream",
     albumLink: "https://www.allmusic.com/album/daydream-mw0003400332",
     artist: "The Buckleys",
     artistLink: "https://www.allmusic.com/artist/the-buckleys-mn0003849707",
     contributions: "Drums"
    },
    {
     year: 2020,
     album: "Country Things, Vol. 2",
     albumLink: "https://www.allmusic.com/album/country-things-vol-2-mw0003439155",
     artist: "Granger Smith",
     artistLink: "https://www.allmusic.com/artist/granger-smith-mn0000698498",
     contributions: "Drums, Percussion, Recording"
    },
    {
     year: 2020,
     album: "Country Things",
     albumLink: "https://www.allmusic.com/album/country-things-mw0003441952",
     artist: "Granger Smith",
     artistLink: "https://www.allmusic.com/artist/granger-smith-mn0000698498",
     contributions: "Drums, Percussion, Assistant, Recording"
    },
    {
     year: 2020,
     album: "Country State of Mind",
     albumLink: "https://www.allmusic.com/album/country-state-of-mind-mw0003396588",
     artist: "Josh Turner",
     artistLink: "https://www.allmusic.com/artist/josh-turner-mn0000277764",
     contributions: "Engineer, Drums, Percussion, Recording"
    },
    {
     year: 2020,
     album: "Called",
     albumLink: "https://www.allmusic.com/album/called-mw0003311885",
     artist: "Avalon",
     artistLink: "https://www.allmusic.com/artist/avalon-mn0000062455",
     contributions: "Drums"
    },
    {
     year: 2020,
     album: "Bridges",
     albumLink: "https://www.allmusic.com/album/bridges-mw0003427028",
     artist: "Mickey Guyton",
     artistLink: "https://www.allmusic.com/artist/mickey-guyton-mn0003237096",
     contributions: "Drums, Percussion"
    },
    {
     year: 2020,
     album: "Born Here Live Here Die Here",
     albumLink: "https://www.allmusic.com/album/born-here-live-here-die-here-mw0003359167",
     artist: "Luke Bryan",
     artistLink: "https://www.allmusic.com/artist/luke-bryan-mn0000502198",
     contributions: "Drums, Percussion"
    },
    {
     year: 2020,
     album: "Bettie James",
     albumLink: "https://www.allmusic.com/album/bettie-james-mw0003399848",
     artist: "Jimmie Allen",
     artistLink: "https://www.allmusic.com/artist/jimmie-allen-mn0003672595",
     contributions: "Bass, Drums, Percussion, Programmer, Synthesizer Bass"
    },
    {
     year: 2020,
     album: "Behind This Guitar",
     albumLink: "https://www.allmusic.com/album/behind-this-guitar-mw0003346128",
     artist: "José Feliciano",
     artistLink: "https://www.allmusic.com/artist/jos%C3%A9-feliciano-mn0000271113",
     contributions: "Drums, Percussion"
    },
    {
     year: 2020,
     album: "A Holly Dolly Christmas",
     albumLink: "https://www.allmusic.com/album/a-holly-dolly-christmas-mw0003417881",
     artist: "Dolly Parton",
     artistLink: "https://www.allmusic.com/artist/dolly-parton-mn0000175286",
     contributions: "Drums"
    },
    {
     year: 2019,
     album: "Slingshot",
     albumLink: "https://www.allmusic.com/album/slingshot-mw0003330320",
     artist: "Stephanie Owens",
     artistLink: "https://www.allmusic.com/artist/stephanie-owens-mn0003722058",
     contributions: "Drums"
    },
    {
     year: 2019,
     album: "Real Friends",
     albumLink: "https://www.allmusic.com/album/real-friends-mw0003317084",
     artist: "Chris Janson",
     artistLink: "https://www.allmusic.com/artist/chris-janson-mn0001069933",
     contributions: "Drums, Percussion"
    },
    {
     year: 2019,
     album: "Please Come Home for Christmas",
     albumLink: "https://www.allmusic.com/album/please-come-home-for-christmas-mw0003334706",
     artist: "Aaron Goodvin",
     artistLink: "https://www.allmusic.com/artist/aaron-goodvin-mn0002987132",
     contributions: "Drums"
    },
    {
     year: 2019,
     album: "Made in America",
     albumLink: "https://www.allmusic.com/album/made-in-america-mw0003286405",
     artist: "Tracy Lawrence",
     artistLink: "https://www.allmusic.com/artist/tracy-lawrence-mn0000746387",
     contributions: "Drums"
    },
    {
     year: 2019,
     album: "Late Nights and Longnecks",
     albumLink: "https://www.allmusic.com/album/late-nights-and-longnecks-mw0003260312",
     artist: "Justin Moore",
     artistLink: "https://www.allmusic.com/artist/justin-moore-mn0000864411",
     contributions: "Drums"
    },
    {
     year: 2019,
     album: "Greatest Hits: The Show Dog Years",
     albumLink: "https://www.allmusic.com/album/greatest-hits-the-show-dog-years-mw0003307976",
     artist: "Toby Keith",
     artistLink: "https://www.allmusic.com/artist/toby-keith-mn0000510805",
     contributions: "Drums"
    },
    {
     year: 2019,
     album: "Firm Foundation",
     albumLink: "https://www.allmusic.com/album/firm-foundation-mw0003320624",
     artist: "Selah",
     artistLink: "https://www.allmusic.com/artist/selah-mn0000004685",
     contributions: "Drums"
    },
    {
     year: 2019,
     album: "Caught Up in the Country",
     albumLink: "https://www.allmusic.com/album/caught-up-in-the-country-mw0003287075",
     artist: "Rodney Atkins",
     artistLink: "https://www.allmusic.com/artist/rodney-atkins-mn0000298824",
     contributions: "Drums"
    },
    {
     year: 2019,
     album: "Blood",
     albumLink: "https://www.allmusic.com/album/blood-mw0003311525",
     artist: "Allison Moorer",
     artistLink: "https://www.allmusic.com/artist/allison-moorer-mn0000746697",
     contributions: "Drums"
    },
    {
     year: 2019,
     album: 9,
     albumLink: "https://www.allmusic.com/album/9-mw0003316386",
     artist: "Jason Aldean",
     artistLink: "https://www.allmusic.com/artist/jason-aldean-mn0000318882",
     contributions: "Programming, Programmer"
    },
    {
     year: 2018,
     album: "What We're Made Of",
     albumLink: "https://www.allmusic.com/album/what-were-made-of-mw0003172829",
     artist: "The Washboard Union",
     artistLink: "https://www.allmusic.com/artist/the-washboard-union-mn0002980203",
     contributions: "Drums, Percussion, Programmer"
    },
    {
     year: 2018,
     album: "The Word: A Nashville Tribute to the Bible",
     albumLink: "https://www.allmusic.com/album/the-word-a-nashville-tribute-to-the-bible-mw0003215260",
     artist: "Nashville Tribute Band",
     artistLink: "https://www.allmusic.com/artist/nashville-tribute-band-mn0001945591",
     contributions: "Drums"
    },
    {
     year: 2018,
     album: "The Greatest Day",
     albumLink: "https://www.allmusic.com/album/the-greatest-day-mw0003188394",
     artist: "Jake Shimabukuro",
     artistLink: "https://www.allmusic.com/artist/jake-shimabukuro-mn0000131075",
     contributions: "Drums, Percussion"
    },
    {
     year: 2018,
     album: "Talk of This Town",
     albumLink: "https://www.allmusic.com/album/talk-of-this-town-mw0003177391",
     artist: "Catherine McGrath",
     artistLink: "https://www.allmusic.com/artist/catherine-mcgrath-mn0003569043",
     contributions: "Drums"
    },
    {
     year: 2018,
     album: "Stephanie Owens",
     albumLink: "https://www.allmusic.com/album/stephanie-owens-mw0003162136",
     artist: "Stephanie Owens",
     artistLink: "https://www.allmusic.com/artist/stephanie-owens-mn0003722058",
     contributions: "Drums"
    },
    {
     year: 2018,
     album: "Randall King",
     albumLink: "https://www.allmusic.com/album/randall-king-mw0003178648",
     artist: "Randall King",
     artistLink: "https://www.allmusic.com/artist/randall-king-mn0003571253",
     contributions: "Drums"
    },
    {
     year: 2018,
     album: "Raising the Bar",
     albumLink: "https://www.allmusic.com/album/raising-the-bar-mw0003210521",
     artist: "Terri Clark",
     artistLink: "https://www.allmusic.com/artist/terri-clark-mn0000750131",
     contributions: "Drums"
    },
    {
     year: 2018,
     album: "Mercury Lane",
     albumLink: "https://www.allmusic.com/album/mercury-lane-mw0003204024",
     artist: "Jimmie Allen",
     artistLink: "https://www.allmusic.com/artist/jimmie-allen-mn0003672595",
     contributions: "Drums, Programming"
    },
    {
     year: 2018,
     album: "Little Girl in the Mirror",
     albumLink: "https://www.allmusic.com/album/little-girl-in-the-mirror-mw0003162119",
     artist: "Stephanie Owens",
     artistLink: "https://www.allmusic.com/artist/stephanie-owens-mn0003722058",
     contributions: "Drums"
    },
    {
     year: 2018,
     album: "Lay Your Head Down",
     albumLink: "https://www.allmusic.com/album/lay-your-head-down-mw0003180292",
     artist: "Carolina Story",
     artistLink: "https://www.allmusic.com/artist/carolina-story-mn0002019787",
     contributions: "Drums"
    },
    {
     year: 2018,
     album: "I Serve a Savior",
     albumLink: "https://www.allmusic.com/album/i-serve-a-savior-mw0003208306",
     artist: "Josh Turner",
     artistLink: "https://www.allmusic.com/artist/josh-turner-mn0000277764",
     contributions: "Drums"
    },
    {
     year: 2018,
     album: "Heart Taker",
     albumLink: "https://www.allmusic.com/album/heart-taker-mw0003162134",
     artist: "Stephanie Owens",
     artistLink: "https://www.allmusic.com/artist/stephanie-owens-mn0003722058",
     contributions: "Drums"
    },
    {
     year: 2018,
     album: "Heart Road",
     albumLink: "https://www.allmusic.com/album/heart-road-mw0003194928",
     artist: "Jack Taylor",
     artistLink: "https://www.allmusic.com/artist/jack-taylor-mn0003045004",
     contributions: "Drums"
    },
    {
     year: 2017,
     album: "Unapologetically",
     albumLink: "https://www.allmusic.com/album/unapologetically-mw0003095048",
     artist: "Kelsea Ballerini",
     artistLink: "https://www.allmusic.com/artist/kelsea-ballerini-mn0003312551",
     contributions: "Drums"
    },
    {
     year: 2017,
     album: "This Ride",
     albumLink: "https://www.allmusic.com/album/this-ride-mw0003098523",
     artist: "Jerrod Niemann",
     artistLink: "https://www.allmusic.com/artist/jerrod-niemann-mn0001449541",
     contributions: "Drum Loop, Drums, Keyboards, Percussion, Programming, Synthesizer"
    },
    {
     year: 2017,
     album: "Silverado Signature Songwriter Series, Vol. 3",
     albumLink: "https://www.allmusic.com/album/silverado-signature-songwriter-series-vol-3-mw0003162814",
     artist: "Tyler Jordan",
     artistLink: "https://www.allmusic.com/artist/tyler-jordan-mn0003563956",
     contributions: "Drums, Percussion"
    },
    {
     year: 2017,
     album: "Road Less Traveled",
     albumLink: "https://www.allmusic.com/album/road-less-traveled-mw0003009534",
     artist: "Lauren Alaina",
     artistLink: "https://www.allmusic.com/artist/lauren-alaina-mn0002702852",
     contributions: "Drums, Percussion, Programming"
    },
    {
     year: 2017,
     album: "Postcards in the Sky",
     albumLink: "https://www.allmusic.com/album/postcards-in-the-sky-mw0003117892",
     artist: "David Archuleta",
     artistLink: "https://www.allmusic.com/artist/david-archuleta-mn0001018022",
     contributions: "Drums"
    },
    {
     year: 2017,
     album: "I Don't Believe We've Met",
     albumLink: "https://www.allmusic.com/album/i-dont-believe-weve-met-mw0003118176",
     artist: "Danielle Bradbery",
     artistLink: "https://www.allmusic.com/artist/danielle-bradbery-mn0003121194",
     contributions: "Drums"
    },
    {
     year: 2017,
     album: "Dana's Best Jump &amp; Jam Tunes",
     albumLink: "https://www.allmusic.com/album/danas-best-jump-amp-jam-tunes-mw0003032425",
     artist: "Dana",
     artistLink: "https://www.allmusic.com/artist/dana-mn0002074718",
     contributions: "Drums"
    },
    {
     year: 2017,
     album: "Back to Us",
     albumLink: "https://www.allmusic.com/album/back-to-us-mw0003038647",
     artist: "Rascal Flatts",
     artistLink: "https://www.allmusic.com/artist/rascal-flatts-mn0000868174",
     contributions: "Drum, Drums"
    },
    {
     year: 2016,
     album: "The Music of Nashville: Season 4, Vol. 2",
     albumLink: "https://www.allmusic.com/album/the-music-of-nashville-season-4-vol-2-mw0002943389",
     artist: "Nashville Cast",
     artistLink: "https://www.allmusic.com/artist/nashville-cast-mn0003027649",
     contributions: "Drums, Percussion"
    },
    {
     year: 2016,
     album: "The Fighters",
     albumLink: "https://www.allmusic.com/album/the-fighters-mw0002937145",
     artist: "LoCash",
     artistLink: "https://www.allmusic.com/artist/locash-mn0003408264",
     contributions: "Drums"
    },
    {
     year: 2016,
     album: "Stoned to Death",
     albumLink: "https://www.allmusic.com/album/stoned-to-death-mw0002956718",
     artist: "James Dupré",
     artistLink: "https://www.allmusic.com/artist/james-dupr%C3%A9-mn0002438639",
     contributions: "Drums"
    },
    {
     year: 2016,
     album: "Nashville Sessions",
     albumLink: "https://www.allmusic.com/album/nashville-sessions-mw0002960618",
     artist: "Jake Shimabukuro",
     artistLink: "https://www.allmusic.com/artist/jake-shimabukuro-mn0000131075",
     contributions: "Featured Artist, Drums, Percussion, Composer"
    },
    {
     year: 2016,
     album: "Music Is Medicine",
     albumLink: "https://www.allmusic.com/album/music-is-medicine-mw0002901014",
     artist: "Marie Osmond",
     artistLink: "https://www.allmusic.com/artist/marie-osmond-mn0000270060",
     contributions: "Drums"
    },
    {
     year: 2016,
     album: "Kinda Don't Care",
     albumLink: "https://www.allmusic.com/album/kinda-dont-care-mw0002960603",
     artist: "Justin Moore",
     artistLink: "https://www.allmusic.com/artist/justin-moore-mn0000864411",
     contributions: "Drums"
    },
    {
     year: 2016,
     album: "Inheritance",
     albumLink: "https://www.allmusic.com/album/inheritance-mw0002909462",
     artist: "Audrey Assad",
     artistLink: "https://www.allmusic.com/artist/audrey-assad-mn0002036413",
     contributions: "Drums"
    },
    {
     year: 2016,
     album: "Call Me Old-Fashioned",
     albumLink: "https://www.allmusic.com/album/call-me-old-fashioned-mw0002974140",
     artist: "Bradley Walker",
     artistLink: "https://www.allmusic.com/artist/bradley-walker-mn0000531656",
     contributions: "Drums"
    },
    {
     year: 2014,
     album: "Undefeated",
     albumLink: "https://www.allmusic.com/album/undefeated-mw0002724378",
     artist: "Secondhand Serenade",
     artistLink: "https://www.allmusic.com/artist/secondhand-serenade-mn0000623567",
     contributions: "Drums"
    },
    {
     year: 2014,
     album: "The Mighty Storm",
     albumLink: "https://www.allmusic.com/album/the-mighty-storm-mw0002613259",
     artist: "Peter Bradley Adams",
     artistLink: "https://www.allmusic.com/artist/peter-bradley-adams-mn0000977208",
     contributions: "Drums"
    },
    {
     year: 2014,
     album: "Redeemer: A Nashville Tribute to Jesus Christ",
     albumLink: "https://www.allmusic.com/album/redeemer-a-nashville-tribute-to-jesus-christ-mw0002739176",
     artist: "Nashville Tribute Band",
     artistLink: "https://www.allmusic.com/artist/nashville-tribute-band-mn0001945591",
     contributions: "Drums, Percussion, Bass"
    },
    {
     year: 2014,
     album: "Horizons",
     albumLink: "https://www.allmusic.com/album/horizons-mw0002697785",
     artist: "Kris Allen",
     artistLink: "https://www.allmusic.com/artist/kris-allen-mn0000709715",
     contributions: "Drums"
    },
    {
     year: 2014,
     album: "Clover Lane",
     albumLink: "https://www.allmusic.com/album/clover-lane-mw0002682738",
     artist: "Jonah Tolchin",
     artistLink: "https://www.allmusic.com/artist/jonah-tolchin-mn0003015804",
     contributions: "Drums, Percussion"
    },
    {
     year: 2013,
     album: "Thorn in My Heart: The Work Tapes",
     albumLink: "https://www.allmusic.com/album/thorn-in-my-heart-the-work-tapes-mw0002478893",
     artist: "Kim Richey",
     artistLink: "https://www.allmusic.com/artist/kim-richey-mn0000189414",
     contributions: "Drums"
    },
    {
     year: 2013,
     album: "The Only",
     albumLink: "https://www.allmusic.com/album/the-only-mw0002585092",
     artist: "Heidi Feek",
     artistLink: "https://www.allmusic.com/artist/heidi-feek-mn0001026110",
     contributions: "Drums, Handclapping"
    },
    {
     year: 2013,
     album: "The Ghost of Escondido",
     albumLink: "https://www.allmusic.com/album/the-ghost-of-escondido-mw0002486090",
     artist: "Escondido",
     artistLink: "https://www.allmusic.com/artist/escondido-mn0003059344",
     contributions: "Drums"
    },
    {
     year: 2013,
     album: "Inspired: Songs of Faith &amp; Family",
     albumLink: "https://www.allmusic.com/album/inspired-songs-of-faith-amp-family-mw0002557935",
     artist: "Joey + Rory",
     artistLink: "https://www.allmusic.com/artist/joey-rory-mn0001019002",
     contributions: "Drums"
    },
    {
     year: 2013,
     album: "How to Sleep in a Stormy Boat",
     albumLink: "https://www.allmusic.com/album/how-to-sleep-in-a-stormy-boat-mw0002530322",
     artist: "Amy Speace",
     artistLink: "https://www.allmusic.com/artist/amy-speace-mn0000866825",
     contributions: "Drums, Percussion"
    },
    {
     year: 2012,
     album: "Time Can Change",
     albumLink: "https://www.allmusic.com/album/time-can-change-mw0002364718",
     artist: "Seth Walker",
     artistLink: "https://www.allmusic.com/artist/seth-walker-mn0000732799",
     contributions: "Percussion"
    },
    {
     year: 2011,
     album: "Land Like a Bird",
     albumLink: "https://www.allmusic.com/album/land-like-a-bird-mw0002111449",
     artist: "Amy Speace",
     artistLink: "https://www.allmusic.com/artist/amy-speace-mn0000866825",
     contributions: "Drums, Percussion"
    },
    {
     year: 2010,
     album: "The Learner",
     albumLink: "https://www.allmusic.com/album/the-learner-mw0001996568",
     artist: "Griffin House",
     artistLink: "https://www.allmusic.com/artist/griffin-house-mn0000212956",
     contributions: "Percussion"
    },
    {
     year: 1997,
     album: "New Country Christmas",
     albumLink: "https://www.allmusic.com/album/new-country-christmas-mw0000005958",
     contributions: "Drums"
    },
    {
     album: "You Matter",
     albumLink: "https://www.allmusic.com/album/you-matter-mw0003013153",
     artist: "Curtis Braly",
     artistLink: "https://www.allmusic.com/artist/curtis-braly-mn0003245559",
     contributions: "Drums, Programming"
    },
    {
     album: "Wreck Me",
     albumLink: "https://www.allmusic.com/album/wreck-me-mw0003416258",
     artist: "Travis Collins",
     artistLink: "https://www.allmusic.com/artist/travis-collins-mn0000458641",
     contributions: "Drums"
    },
    {
     album: "Words in Your Eyes",
     albumLink: "https://www.allmusic.com/album/words-in-your-eyes-mw0002995433",
     artist: "Brooke Annibale",
     artistLink: "https://www.allmusic.com/artist/brooke-annibale-mn0001967426",
     contributions: "Drums"
    },
    {
     album: "Women of Big Machine 2022",
     albumLink: "https://www.allmusic.com/album/women-of-big-machine-2022-mw0003725485",
     contributions: "Drums"
    },
    {
     album: "Woman You Got",
     albumLink: "https://www.allmusic.com/album/woman-you-got-mw0003508775",
     artist: "Maddie &amp; Tae",
     artistLink: "https://www.allmusic.com/artist/maddie-tae-mn0003282505",
     contributions: "Drums"
    },
    {
     album: "Wild Angel",
     albumLink: "https://www.allmusic.com/album/wild-angel-mw0003171495",
     artist: "The Washboard Union",
     artistLink: "https://www.allmusic.com/artist/the-washboard-union-mn0002980203",
     contributions: "Drums, Percussion, Programmer"
    },
    {
     album: "Whiskey Glasses",
     albumLink: "https://www.allmusic.com/album/whiskey-glasses-mw0003583555",
     contributions: "Drums"
    },
    {
     album: "We're Not Friends",
     albumLink: "https://www.allmusic.com/album/were-not-friends-mw0003310944",
     artist: "Ingrid Andress",
     artistLink: "https://www.allmusic.com/artist/ingrid-andress-mn0002596016",
     contributions: "Drums"
    },
    {
     album: "We Didn't Have Much",
     albumLink: "https://www.allmusic.com/album/we-didnt-have-much-mw0003437964",
     artist: "Justin Moore",
     artistLink: "https://www.allmusic.com/artist/justin-moore-mn0000864411",
     contributions: "Drums"
    },
    {
     album: "Ways to Miss You",
     albumLink: "https://www.allmusic.com/album/ways-to-miss-you-mw0003548989",
     artist: "Tyler Braden",
     artistLink: "https://www.allmusic.com/artist/tyler-braden-mn0003934030",
     contributions: "Drums, Percussion"
    },
    {
     album: "Wanderer",
     albumLink: "https://www.allmusic.com/album/wanderer-mw0003495064",
     artist: "Corey Fernandez",
     artistLink: "https://www.allmusic.com/artist/corey-fernandez-mn0004037114",
     contributions: "Drums, Percussion"
    },
    {
     album: "Walking With a Stranger",
     albumLink: "https://www.allmusic.com/album/walking-with-a-stranger-mw0002913329",
     artist: "Escondido",
     artistLink: "https://www.allmusic.com/artist/escondido-mn0003059344",
     contributions: "Drums"
    },
    {
     album: "Viral Summer",
     albumLink: "https://www.allmusic.com/album/viral-summer-mw0003559485",
     contributions: "Drums"
    },
    {
     album: "Vibras Country",
     albumLink: "https://www.allmusic.com/album/vibras-country-mw0003910019",
     contributions: "Drums"
    },
    {
     album: "Veterans Day Country",
     albumLink: "https://www.allmusic.com/album/veterans-day-country-mw0003748469",
     contributions: "Drums"
    },
    {
     album: "Try Missing You",
     albumLink: "https://www.allmusic.com/album/try-missing-you-mw0003556475",
     artist: "Jon Langston",
     artistLink: "https://www.allmusic.com/artist/jon-langston-mn0003397785",
     contributions: "Drums"
    },
    {
     album: "Timeless",
     albumLink: "https://www.allmusic.com/album/timeless-mw0003318301",
     artist: "Valerie Ponzio",
     artistLink: "https://www.allmusic.com/artist/valerie-ponzio-mn0003649178",
     contributions: "Drums"
    },
    {
     album: "Tik Tock Christmas Songs",
     albumLink: "https://www.allmusic.com/album/tik-tock-christmas-songs-mw0003722656",
     contributions: "Drums"
    },
    {
     album: "Tiera",
     albumLink: "https://www.allmusic.com/album/tiera-mw0003655843",
     artist: "Tiera",
     artistLink: "https://www.allmusic.com/artist/tiera-mn0004184526",
     contributions: "Drums"
    },
    {
     album: "Thinkin' We're in Love",
     albumLink: "https://www.allmusic.com/album/thinkin-were-in-love-mw0003783701",
     artist: "Tyler Rich",
     artistLink: "https://www.allmusic.com/artist/tyler-rich-mn0003716382",
     contributions: "Drums"
    },
    {
     album: "The Old Beach",
     albumLink: "https://www.allmusic.com/album/the-old-beach-mw0003788374",
     contributions: "Drums"
    },
    {
     album: "The Manger",
     albumLink: "https://www.allmusic.com/album/the-manger-mw0003872880",
     artist: "Anne Wilson",
     artistLink: "https://www.allmusic.com/artist/anne-wilson-mn0001797117",
     contributions: "Drums"
    },
    {
     album: "The Ice Road",
     albumLink: "https://www.allmusic.com/album/the-ice-road-mw0003585780",
     contributions: "Drum Programming"
    },
    {
     album: "The Nashville Calling",
     albumLink: "https://www.allmusic.com/album/the-nashville-calling-mw0003368094",
     artist: "Ben Reel",
     artistLink: "https://www.allmusic.com/artist/ben-reel-mn0001945566",
     contributions: "Drums, Percussion"
    },
    {
     album: "The Best Country Album in the World...Ever! [2021]",
     albumLink: "https://www.allmusic.com/album/the-best-country-album-in-the-worldever%21-2021--mw0003572280",
     contributions: "Drums"
    },
    {
     album: "The Answer",
     albumLink: "https://www.allmusic.com/album/the-answer-mw0003514810",
     artist: "Josh Turner",
     artistLink: "https://www.allmusic.com/artist/josh-turner-mn0000277764",
     contributions: "Drums"
    },
    {
     album: "Talking to God",
     albumLink: "https://www.allmusic.com/album/talking-to-god-mw0003373693",
     artist: "Chrissy Metz",
     artistLink: "https://www.allmusic.com/artist/chrissy-metz-mn0003677503",
     contributions: "Drums"
    },
    {
     album: "Take My Name",
     albumLink: "https://www.allmusic.com/album/take-my-name-mw0003550224",
     artist: "Parmalee",
     artistLink: "https://www.allmusic.com/artist/parmalee-mn0000130987",
     contributions: "Drums"
    },
    {
     album: "Take Me Away",
     albumLink: "https://www.allmusic.com/album/take-me-away-mw0002547531",
     artist: "Katy McAllister",
     artistLink: "https://www.allmusic.com/artist/katy-mcallister-mn0003118867",
     contributions: "Engineer, Drums"
    },
    {
     album: "Tailgate [Universal]",
     albumLink: "https://www.allmusic.com/album/tailgate-universal--mw0003803785",
     contributions: "Drums"
    },
    {
     album: "Tailgate Country",
     albumLink: "https://www.allmusic.com/album/tailgate-country-mw0003957541",
     contributions: "Drums, Percussion"
    },
    {
     album: "Sweet Summertime 2022",
     albumLink: "https://www.allmusic.com/album/sweet-summertime-2022-mw0003837415",
     contributions: "Drums"
    },
    {
     album: "Summertime Country Hits 2022",
     albumLink: "https://www.allmusic.com/album/summertime-country-hits-2022-mw0003881534",
     contributions: "Drums"
    },
    {
     album: "Summer Ragers 2021",
     albumLink: "https://www.allmusic.com/album/summer-ragers-2021-mw0003565856",
     contributions: "Drums"
    },
    {
     album: "Summer Pool Party 2021",
     albumLink: "https://www.allmusic.com/album/summer-pool-party-2021-mw0003524437",
     contributions: "Drums"
    },
    {
     album: "Summer Party Country",
     albumLink: "https://www.allmusic.com/album/summer-party-country-mw0003571036",
     contributions: "Drums"
    },
    {
     album: "Summer Feels 2022",
     albumLink: "https://www.allmusic.com/album/summer-feels-2022-mw0003866017",
     contributions: "Drums"
    },
    {
     album: "Summer Country Songs",
     albumLink: "https://www.allmusic.com/album/summer-country-songs-mw0003809460",
     contributions: "Drums, Tambourine"
    },
    {
     album: "Summer Country 2022",
     albumLink: "https://www.allmusic.com/album/summer-country-2022-mw0003954606",
     contributions: "Drums"
    },
    {
     album: "Summer BBQ: Country",
     albumLink: "https://www.allmusic.com/album/summer-bbq-country-mw0003625948",
     contributions: "Drums"
    },
    {
     album: "Summer 2021[Universal]",
     albumLink: "https://www.allmusic.com/album/summer-2021-universal--mw0003524438",
     contributions: "Drums"
    },
    {
     album: "Stubborn Pride",
     albumLink: "https://www.allmusic.com/album/stubborn-pride-mw0003570886",
     artist: "Zac Brown Band",
     artistLink: "https://www.allmusic.com/artist/zac-brown-band-mn0001021310",
     contributions: "Drums"
    },
    {
     album: "Streetlights",
     albumLink: "https://www.allmusic.com/album/streetlights-mw0003537087",
     artist: "Frank Ray",
     artistLink: "https://www.allmusic.com/artist/frank-ray-mn0003023555",
     contributions: "Drums, Percussion"
    },
    {
     album: "Strangers",
     albumLink: "https://www.allmusic.com/album/strangers-mw0003652609",
     artist: "Maddie &amp; Tae",
     artistLink: "https://www.allmusic.com/artist/maddie-tae-mn0003282505",
     contributions: "Drums"
    },
    {
     album: "Stoned Cold Country",
     albumLink: "https://www.allmusic.com/album/stoned-cold-country-mw0003861821",
     contributions: "Drums"
    },
    {
     album: "Step Into Summer",
     albumLink: "https://www.allmusic.com/album/step-into-summer-mw0003526238",
     contributions: "Drums"
    },
    {
     album: "Stay Here With Me",
     albumLink: "https://www.allmusic.com/album/stay-here-with-me-mw0002587365",
     artist: "Jesse Terry",
     artistLink: "https://www.allmusic.com/artist/jesse-terry-mn0002041661",
     contributions: "Drums, Percussion"
    },
    {
     album: "Spring Cleaning",
     albumLink: "https://www.allmusic.com/album/spring-cleaning-mw0003808670",
     artist: "Maddie &amp; Tae",
     artistLink: "https://www.allmusic.com/artist/maddie-tae-mn0003282505",
     contributions: "Drums"
    },
    {
     album: "Spring Break Country 2022",
     albumLink: "https://www.allmusic.com/album/spring-break-country-2022-mw0003856768",
     contributions: "Drums"
    },
    {
     album: "Spring Break Country 2021",
     albumLink: "https://www.allmusic.com/album/spring-break-country-2021-mw0003502713",
     contributions: "Drums"
    },
    {
     album: "Spring Break Country 2020",
     albumLink: "https://www.allmusic.com/album/spring-break-country-2020-mw0003366962",
     contributions: "Drums"
    },
    {
     album: "Spooky Country",
     albumLink: "https://www.allmusic.com/album/spooky-country-mw0003730671",
     contributions: "Drums, Percussion"
    },
    {
     album: "Southern Iron",
     albumLink: "https://www.allmusic.com/album/southern-iron-mw0002943724",
     artist: "Derik Hultquist",
     artistLink: "https://www.allmusic.com/artist/derik-hultquist-mn0003255258",
     contributions: "Drums, Percussion"
    },
    {
     album: "Soundtrack to Summer 2022 [Deluxe Edition]",
     albumLink: "https://www.allmusic.com/album/soundtrack-to-summer-2022-deluxe-edition--mw0003893314",
     contributions: "Drums"
    },
    {
     album: "Soundtrack to Summer 2021",
     albumLink: "https://www.allmusic.com/album/soundtrack-to-summer-2021-mw0003571037",
     contributions: "Drums"
    },
    {
     album: "Sounds Good to Me",
     albumLink: "https://www.allmusic.com/album/sounds-good-to-me-mw0003263396",
     artist: "The Abrams",
     artistLink: "https://www.allmusic.com/artist/the-abrams-mn0003500592",
     contributions: "Drums, Percussion"
    },
    {
     album: "Souls",
     albumLink: "https://www.allmusic.com/album/souls-mw0003077874",
     artist: "John McAndrew",
     artistLink: "https://www.allmusic.com/artist/john-mcandrew-mn0001812243",
     contributions: "Drums"
    },
    {
     album: "Songs to Say I Do",
     albumLink: "https://www.allmusic.com/album/songs-to-say-i-do-mw0003856043",
     artist: "Kylie Morgan",
     artistLink: "https://www.allmusic.com/artist/kylie-morgan-mn0003368858",
     contributions: "Drums"
    },
    {
     album: "Songs for Easter [2020]",
     albumLink: "https://www.allmusic.com/album/songs-for-easter-2020--mw0003368106",
     contributions: "Drums"
    },
    {
     album: "Songs You Never Heard",
     albumLink: "https://www.allmusic.com/album/songs-you-never-heard-mw0003571884",
     artist: "Luke Bryan",
     artistLink: "https://www.allmusic.com/artist/luke-bryan-mn0000502198",
     contributions: "Drums"
    },
    {
     album: "Soldier's Gift",
     albumLink: "https://www.allmusic.com/album/soldiers-gift-mw0003617798",
     artist: "Josh Turner",
     artistLink: "https://www.allmusic.com/artist/josh-turner-mn0000277764",
     contributions: "Drums"
    },
    {
     album: "Soft Country Hits",
     albumLink: "https://www.allmusic.com/album/soft-country-hits-mw0003551053",
     contributions: "Drums"
    },
    {
     album: "Small Town Throwdown: Summer Country",
     albumLink: "https://www.allmusic.com/album/small-town-throwdown-summer-country-mw0003912539",
     contributions: "Drums"
    },
    {
     album: "Shoulda",
     albumLink: "https://www.allmusic.com/album/shoulda-mw0003490172",
     artist: "Kylie Morgan",
     artistLink: "https://www.allmusic.com/artist/kylie-morgan-mn0003368858",
     contributions: "Drums, Programming"
    },
    {
     album: "Shot Glass",
     albumLink: "https://www.allmusic.com/album/shot-glass-mw0003679790",
     artist: "Randall King",
     artistLink: "https://www.allmusic.com/artist/randall-king-mn0003571253",
     contributions: "Drums, Programmer, Programming, Shaker, Tambourine"
    },
    {
     album: "She's Got Wings",
     albumLink: "https://www.allmusic.com/album/shes-got-wings-mw0003922467",
     artist: "Dipper",
     artistLink: "https://www.allmusic.com/artist/dipper-mn0000568578",
     contributions: "Drums"
    },
    {
     album: "She Ain’t Mine No More",
     albumLink: "https://www.allmusic.com/album/she-aint-mine-no-more-mw0003506346",
     artist: "Justin Moore",
     artistLink: "https://www.allmusic.com/artist/justin-moore-mn0000864411",
     contributions: "Drums"
    },
    {
     album: "Sexy Summer Staples",
     albumLink: "https://www.allmusic.com/album/sexy-summer-staples-mw0003567212",
     contributions: "Drums, Percussion"
    },
    {
     album: "Seventeen [From “American Song Contest”]",
     albumLink: "https://www.allmusic.com/album/seventeen-from-american-song-contest--mw0003698635",
     artist: "Tyler Braden",
     artistLink: "https://www.allmusic.com/artist/tyler-braden-mn0003934030",
     contributions: "Drums, Percussion"
    },
    {
     album: "Settle On Back",
     albumLink: "https://www.allmusic.com/album/settle-on-back-mw0003735480",
     artist: "Jimmie Allen",
     artistLink: "https://www.allmusic.com/artist/jimmie-allen-mn0003672595",
     contributions: "Drums, Percussion, Programmer"
    },
    {
     album: "Secret",
     albumLink: "https://www.allmusic.com/album/secret-mw0003447575",
     artist: "Tyler Braden",
     artistLink: "https://www.allmusic.com/artist/tyler-braden-mn0003934030",
     contributions: "Drums, Percussion"
    },
    {
     album: "Sad Country Music",
     albumLink: "https://www.allmusic.com/album/sad-country-music-mw0003409040",
     contributions: "Drums, Percussion"
    },
    {
     album: "Run Run Rudolph",
     albumLink: "https://www.allmusic.com/album/run-run-rudolph-mw0003457247",
     artist: "Randall King",
     artistLink: "https://www.allmusic.com/artist/randall-king-mn0003571253",
     contributions: "Drums, Tambourine"
    },
    {
     album: "Ritmos Country",
     albumLink: "https://www.allmusic.com/album/ritmos-country-mw0003759340",
     contributions: "Drums"
    },
    {
     album: "Reminder",
     albumLink: "https://www.allmusic.com/album/reminder-mw0003313808",
     artist: "The Abrams",
     artistLink: "https://www.allmusic.com/artist/the-abrams-mn0003500592",
     contributions: "Drums, Percussion"
    },
    {
     album: "Raised Up Right",
     albumLink: "https://www.allmusic.com/album/raised-up-right-mw0003693012",
     artist: "Drew Parker",
     artistLink: "https://www.allmusic.com/artist/drew-parker-mn0001635160",
     contributions: "Drums, Percussion"
    },
    {
     album: "Ragamuffin: Music Inspired by the Motion Picture",
     albumLink: "https://www.allmusic.com/album/ragamuffin-music-inspired-by-the-motion-picture-mw0002699407",
     contributions: "Drums"
    },
    {
     album: "Radio Country",
     albumLink: "https://www.allmusic.com/album/radio-country-mw0003892310",
     contributions: "Drums"
    },
    {
     album: "Radar de Éxitos: Primavera",
     albumLink: "https://www.allmusic.com/album/radar-de-%C3xitos-primavera-mw0003947305",
     contributions: "Drums"
    },
    {
     album: "Rabbit Runs a Destiny",
     albumLink: "https://www.allmusic.com/album/rabbit-runs-a-destiny-mw0002542787",
     artist: "Duquette Johnston",
     artistLink: "https://www.allmusic.com/artist/duquette-johnston-mn0001531698",
     contributions: "Drums"
    },
    {
     album: "Queens Don't",
     albumLink: "https://www.allmusic.com/album/queens-dont-mw0003163035",
     artist: "RaeLynn",
     artistLink: "https://www.allmusic.com/artist/raelynn-mn0002975884",
     contributions: "Drums"
    },
    {
     album: "Prayin' in a Deer Stand",
     albumLink: "https://www.allmusic.com/album/prayin-in-a-deer-stand-mw0003875738",
     artist: "Luke Bryan",
     artistLink: "https://www.allmusic.com/artist/luke-bryan-mn0000502198",
     contributions: "Drums"
    },
    {
     album: "Postmodern Man",
     albumLink: "https://www.allmusic.com/album/postmodern-man-mw0002881354",
     artist: "Clint Alphin",
     artistLink: "https://www.allmusic.com/artist/clint-alphin-mn0000896165",
     contributions: "Drums"
    },
    {
     album: "Positive Country for Kids",
     albumLink: "https://www.allmusic.com/album/positive-country-for-kids-mw0003934872",
     contributions: "Drums, Percussion"
    },
    {
     album: "Poolside Country",
     albumLink: "https://www.allmusic.com/album/poolside-country-mw0003878546",
     contributions: "Drums"
    },
    {
     album: "Open Space",
     albumLink: "https://www.allmusic.com/album/open-space-mw0002815557",
     artist: "Mia Rose Lynne",
     artistLink: "https://www.allmusic.com/artist/mia-rose-lynne-mn0003363309",
     contributions: "Group Member"
    },
    {
     album: "One Margarita",
     albumLink: "https://www.allmusic.com/album/one-margarita-mw0003369225",
     artist: "Luke Bryan",
     artistLink: "https://www.allmusic.com/artist/luke-bryan-mn0000502198",
     contributions: "Drums"
    },
    {
     album: "Old Time Christmas",
     albumLink: "https://www.allmusic.com/album/old-time-christmas-mw0003867362",
     artist: "Randall King",
     artistLink: "https://www.allmusic.com/artist/randall-king-mn0003571253",
     contributions: "Drums"
    },
    {
     album: "Official Father's Day Playlist",
     albumLink: "https://www.allmusic.com/album/official-fathers-day-playlist-mw0003756973",
     contributions: "Drums"
    },
    {
     album: "Now That's What I Call Music!, Vol. 76",
     albumLink: "https://www.allmusic.com/album/now-thats-what-i-call-music%21-vol-76-mw0003423279",
     contributions: "Drums"
    },
    {
     album: "Now That's What I Call Country, Vol. 13",
     albumLink: "https://www.allmusic.com/album/now-thats-what-i-call-country-vol-13-mw0003402161",
     contributions: "Drums"
    },
    {
     album: "Now Country: Hits &amp; Rising Stars 2",
     albumLink: "https://www.allmusic.com/album/now-country-hits-amp-rising-stars-2-mw0003591021",
     contributions: "Drums"
    },
    {
     album: "New Year's Party Country",
     albumLink: "https://www.allmusic.com/album/new-years-party-country-mw0003676506",
     contributions: "Drums"
    },
    {
     album: "New Medicine",
     albumLink: "https://www.allmusic.com/album/new-medicine-mw0003022444",
     artist: "Michael Logen",
     artistLink: "https://www.allmusic.com/artist/michael-logen-mn0001960180",
     contributions: "Drums"
    },
    {
     album: "New Country Hits: September [2021]",
     albumLink: "https://www.allmusic.com/album/new-country-hits-september-2021--mw0003656114",
     contributions: "Drums, Programming"
    },
    {
     album: "New Country Hits: October 2021",
     albumLink: "https://www.allmusic.com/album/new-country-hits-october-2021-mw0003818071",
     contributions: "Drums, Programming"
    },
    {
     album: "New Country Hits: November 2021",
     albumLink: "https://www.allmusic.com/album/new-country-hits-november-2021-mw0003723765",
     contributions: "Drums, Percussion"
    },
    {
     album: "New Country Hits: May 2022",
     albumLink: "https://www.allmusic.com/album/new-country-hits-may-2022-mw0003896481",
     contributions: "Drums, Percussion, Programming"
    },
    {
     album: "New Country Hits: June 2022",
     albumLink: "https://www.allmusic.com/album/new-country-hits-june-2022-mw0003811809",
     contributions: "Drums"
    },
    {
     album: "New Country Hits: January 2022",
     albumLink: "https://www.allmusic.com/album/new-country-hits-january-2022-mw0003919549",
     contributions: "Drums, Percussion, Programming"
    },
    {
     album: "New Country Hits: February 2022",
     albumLink: "https://www.allmusic.com/album/new-country-hits-february-2022-mw0003778024",
     contributions: "Drums, Percussion"
    },
    {
     album: "New Country Hits: December 2021",
     albumLink: "https://www.allmusic.com/album/new-country-hits-december-2021-mw0003778025",
     contributions: "Drums, Percussion, Programming"
    },
    {
     album: "New Country Hits July 2021",
     albumLink: "https://www.allmusic.com/album/new-country-hits-july-2021-mw0003559250",
     contributions: "Drums"
    },
    {
     album: "New Country Hits August 2021",
     albumLink: "https://www.allmusic.com/album/new-country-hits-august-2021-mw0003818074",
     contributions: "Drums, Programming"
    },
    {
     album: "New Christmas Hits 2022",
     albumLink: "https://www.allmusic.com/album/new-christmas-hits-2022-mw0003915488",
     contributions: "Drum, Drums"
    },
    {
     album: "Nashville Christmas",
     albumLink: "https://www.allmusic.com/album/nashville-christmas-mw0003808699",
     contributions: "Drums"
    },
    {
     album: "Name on It",
     albumLink: "https://www.allmusic.com/album/name-on-it-mw0003383179",
     artist: "Ryan Griffin",
     artistLink: "https://www.allmusic.com/artist/ryan-griffin-mn0003115097",
     contributions: "Drums"
    },
    {
     album: "Name on It",
     albumLink: "https://www.allmusic.com/album/name-on-it-mw0003421973",
     artist: "Ryan Griffin",
     artistLink: "https://www.allmusic.com/artist/ryan-griffin-mn0003115097",
     contributions: "Drums"
    },
    {
     album: "NOW That's What I Call Country, Vol. 15",
     albumLink: "https://www.allmusic.com/album/now-thats-what-i-call-country-vol-15-mw0003758412",
     contributions: "Drums"
    },
    {
     album: "NOW That's What I Call Country, Vol. 14",
     albumLink: "https://www.allmusic.com/album/now-thats-what-i-call-country-vol-14-mw0003583553",
     contributions: "Drums, Programming"
    },
    {
     album: "Mother's Love: Mother's Day Songs 2022",
     albumLink: "https://www.allmusic.com/album/mothers-love-mothers-day-songs-2022-mw0003725598",
     contributions: "Drums"
    },
    {
     album: "Mood Ring",
     albumLink: "https://www.allmusic.com/album/mood-ring-mw0003530327",
     artist: "Maddie &amp; Tae",
     artistLink: "https://www.allmusic.com/artist/maddie-tae-mn0003282505",
     contributions: "Drums"
    },
    {
     album: "Middle of Nowhere Class",
     albumLink: "https://www.allmusic.com/album/middle-of-nowhere-class-mw0003960968",
     artist: "Drew Parker",
     artistLink: "https://www.allmusic.com/artist/drew-parker-mn0001635160",
     contributions: "Drums"
    },
    {
     album: "Memorial Day [May, 2022]",
     albumLink: "https://www.allmusic.com/album/memorial-day-may-2022--mw0003749178",
     contributions: "Drums"
    },
    {
     album: "Memorial Day Country",
     albumLink: "https://www.allmusic.com/album/memorial-day-country-mw0003749228",
     contributions: "Drums"
    },
    {
     album: "Medley: Take It Back/Why Haven't I Heard From You",
     albumLink: "https://www.allmusic.com/album/medley-take-it-back-why-havent-i-heard-from-you-mw0003606651",
     artist: "Reba McEntire",
     artistLink: "https://www.allmusic.com/artist/reba-mcentire-mn0000409968",
     contributions: "Drums"
    },
    {
     album: "Make My World Go Black",
     albumLink: "https://www.allmusic.com/album/make-my-world-go-black-mw0003532728",
     contributions: "Drums"
    },
    {
     album: "Madness",
     albumLink: "https://www.allmusic.com/album/madness-mw0003671105",
     artist: "Maddie &amp; Tae",
     artistLink: "https://www.allmusic.com/artist/maddie-tae-mn0003282505",
     contributions: "Drums"
    },
    {
     album: "Love, Kylie",
     albumLink: "https://www.allmusic.com/album/love-kylie-mw0003545005",
     artist: "Kylie Morgan",
     artistLink: "https://www.allmusic.com/artist/kylie-morgan-mn0003368858",
     contributions: "Drums, Programming"
    },
    {
     album: "Love Songs 2010-2019",
     albumLink: "https://www.allmusic.com/album/love-songs-2010-2019-mw0003674180",
     artist: "Rascal Flatts",
     artistLink: "https://www.allmusic.com/artist/rascal-flatts-mn0000868174",
     contributions: "Drums"
    },
    {
     album: "Love Like We're Drunk",
     albumLink: "https://www.allmusic.com/album/love-like-were-drunk-mw0003752658",
     artist: "Kylie Morgan",
     artistLink: "https://www.allmusic.com/artist/kylie-morgan-mn0003368858",
     contributions: "Drums"
    },
    {
     album: "Long Hot Summer Night",
     albumLink: "https://www.allmusic.com/album/long-hot-summer-night-mw0003941106",
     contributions: "Drums"
    },
    {
     album: "Livin Outta Livin",
     albumLink: "https://www.allmusic.com/album/livin-outta-livin-mw0003167944",
     artist: "The Washboard Union",
     artistLink: "https://www.allmusic.com/artist/the-washboard-union-mn0002980203",
     contributions: "Drums, Percussion"
    },
    {
     album: "Little Miss Saturday Night",
     albumLink: "https://www.allmusic.com/album/little-miss-saturday-night-mw0003725545",
     artist: "Drew Parker",
     artistLink: "https://www.allmusic.com/artist/drew-parker-mn0001635160",
     contributions: "Percussion"
    },
    {
     album: "Life Ain't Fair",
     albumLink: "https://www.allmusic.com/album/life-aint-fair-mw0003571854",
     artist: "Maddie &amp; Tae",
     artistLink: "https://www.allmusic.com/artist/maddie-tae-mn0003282505",
     contributions: "Drums"
    },
    {
     album: "Lettin' the Night Roll: Summer Songs",
     albumLink: "https://www.allmusic.com/album/lettin-the-night-roll-summer-songs-mw0003744205",
     artist: "Justin Moore",
     artistLink: "https://www.allmusic.com/artist/justin-moore-mn0000864411",
     contributions: "Drums"
    },
    {
     album: "Leanna",
     albumLink: "https://www.allmusic.com/album/leanna-mw0003464665",
     artist: "Randall King",
     artistLink: "https://www.allmusic.com/artist/randall-king-mn0003571253",
     contributions: "Drums, Programmer"
    },
    {
     album: "Latino Infused Country",
     albumLink: "https://www.allmusic.com/album/latino-infused-country-mw0003885153",
     contributions: "Drums"
    },
    {
     album: "King Size Manger",
     albumLink: "https://www.allmusic.com/album/king-size-manger-mw0003602430",
     artist: "Josh Turner",
     artistLink: "https://www.allmusic.com/artist/josh-turner-mn0000277764",
     contributions: "Drums"
    },
    {
     album: "Kids Songs: Country Party!",
     albumLink: "https://www.allmusic.com/album/kids-songs-country-party%21-mw0003805986",
     contributions: "Drums"
    },
    {
     album: "Key to Life",
     albumLink: "https://www.allmusic.com/album/key-to-life-mw0003581985",
     artist: "Bexar",
     artistLink: "https://www.allmusic.com/artist/bexar-mn0003962190",
     contributions: "Drums, Percussion"
    },
    {
     album: "Kentucky Fried Christmas",
     albumLink: "https://www.allmusic.com/album/kentucky-fried-christmas-mw0003808674",
     contributions: "Drums"
    },
    {
     album: "Just the Way",
     albumLink: "https://www.allmusic.com/album/just-the-way-mw0003342646",
     artist: "Parmalee",
     artistLink: "https://www.allmusic.com/artist/parmalee-mn0000130987",
     contributions: "Drums, Percussion"
    },
    {
     album: "Just Love",
     albumLink: "https://www.allmusic.com/album/just-love-mw0002981010",
     artist: "Us the Duo",
     artistLink: "https://www.allmusic.com/artist/us-the-duo-mn0003257289",
     contributions: "Drums"
    },
    {
     album: "It's a Friday Night, It's a Small Town Girl",
     albumLink: "https://www.allmusic.com/album/its-a-friday-night-its-a-small-town-girl-mw0003624306",
     contributions: "Drums"
    },
    {
     album: "It's Spring time",
     albumLink: "https://www.allmusic.com/album/its-spring-time-mw0003546067",
     contributions: "Drums"
    },
    {
     album: "It's A Friday Night",
     albumLink: "https://www.allmusic.com/album/its-a-friday-night-mw0003583556",
     contributions: "Drums"
    },
    {
     album: "It Wasn't His Child",
     albumLink: "https://www.allmusic.com/album/it-wasnt-his-child-mw0003462764",
     artist: "Tim McGraw",
     artistLink: "https://www.allmusic.com/artist/tim-mcgraw-mn0000592954",
     contributions: "Percussion"
    },
    {
     album: "It Is What It Is",
     albumLink: "https://www.allmusic.com/album/it-is-what-it-is-mw0003914216",
     artist: "Jordan Harvey",
     artistLink: "https://www.allmusic.com/artist/jordan-harvey-mn0003876957",
     contributions: "Drums, Recording"
    },
    {
     album: "Inside Looking Out",
     albumLink: "https://www.allmusic.com/album/inside-looking-out-mw0003391963",
     artist: "Stephanie Owens",
     artistLink: "https://www.allmusic.com/artist/stephanie-owens-mn0003722058",
     contributions: "Drums"
    },
    {
     album: "I'll Be Home for Christmas",
     albumLink: "https://www.allmusic.com/album/ill-be-home-for-christmas-mw0003868772",
     artist: "Tiera Kennedy",
     artistLink: "https://www.allmusic.com/artist/tiera-kennedy-mn0004188770",
     contributions: "Drums, Engineer"
    },
    {
     album: "I Only Want You for Christmas",
     albumLink: "https://www.allmusic.com/album/i-only-want-you-for-christmas-mw0003631918",
     artist: "Randall King",
     artistLink: "https://www.allmusic.com/artist/randall-king-mn0003571253",
     contributions: "Drums, Sleigh Bells"
    },
    {
     album: "I Only Date Cowboys",
     albumLink: "https://www.allmusic.com/album/i-only-date-cowboys-mw0003537915",
     artist: "Kylie Morgan",
     artistLink: "https://www.allmusic.com/artist/kylie-morgan-mn0003368858",
     contributions: "Drums, Programming"
    },
    {
     album: "I Needed Christmas",
     albumLink: "https://www.allmusic.com/album/i-needed-christmas-mw0003920488",
     artist: "Reba McEntire",
     artistLink: "https://www.allmusic.com/artist/reba-mcentire-mn0000409968",
     contributions: "Drum, Drums"
    },
    {
     album: "I Love You More",
     albumLink: "https://www.allmusic.com/album/i-love-you-more-mw0003587918",
     artist: "Avery Anna",
     artistLink: "https://www.allmusic.com/artist/avery-anna-mn0004113465",
     contributions: "Drums"
    },
    {
     album: "I Don’t Drink Anymore",
     albumLink: "https://www.allmusic.com/album/i-dont-drink-anymore-mw0003316387",
     artist: "Jason Aldean",
     artistLink: "https://www.allmusic.com/artist/jason-aldean-mn0000318882",
     contributions: "Programmer"
    },
    {
     album: "I Can Tell by the Way You Dance",
     albumLink: "https://www.allmusic.com/album/i-can-tell-by-the-way-you-dance-mw0003416928",
     artist: "Josh Turner",
     artistLink: "https://www.allmusic.com/artist/josh-turner-mn0000277764",
     contributions: "Drums, Percussion"
    },
    {
     album: "Hot Songs for Cold Days 2023",
     albumLink: "https://www.allmusic.com/album/hot-songs-for-cold-days-2023-mw0003941104",
     contributions: "Drums"
    },
    {
     album: "Horses &amp; Weed",
     albumLink: "https://www.allmusic.com/album/horses-amp-weed-mw0003821555",
     artist: "Ian Munsick",
     artistLink: "https://www.allmusic.com/artist/ian-munsick-mn0003638485",
     contributions: "Drums"
    },
    {
     album: "Home for the Holidays [2021]",
     albumLink: "https://www.allmusic.com/album/home-for-the-holidays-2021--mw0003762293",
     contributions: "Drums"
    },
    {
     album: "Hideaway",
     albumLink: "https://www.allmusic.com/album/hideaway-mw0003900836",
     artist: "Brett Eldredge",
     artistLink: "https://www.allmusic.com/artist/brett-eldredge-mn0002419025",
     contributions: "Drums"
    },
    {
     album: "Heavyweight",
     albumLink: "https://www.allmusic.com/album/heavyweight-mw0003649165",
     artist: "Kassi Ashton",
     artistLink: "https://www.allmusic.com/artist/kassi-ashton-mn0003661555",
     contributions: "Drums, Percussion"
    },
    {
     album: "Hear About a Girl",
     albumLink: "https://www.allmusic.com/album/hear-about-a-girl-mw0003821195",
     artist: "Dalton Dover",
     artistLink: "https://www.allmusic.com/artist/dalton-dover-mn0004305627",
     contributions: "Drums"
    },
    {
     album: "Hard Way to Go",
     albumLink: "https://www.allmusic.com/album/hard-way-to-go-mw0003235571",
     artist: "Jimmy Charles",
     artistLink: "https://www.allmusic.com/artist/jimmy-charles-mn0003445040",
     contributions: "Keyboards, Drums, Percussion, Programming"
    },
    {
     album: "Happy Ever After",
     albumLink: "https://www.allmusic.com/album/happy-ever-after-mw0003445060",
     artist: "Jon Langston",
     artistLink: "https://www.allmusic.com/artist/jon-langston-mn0003397785",
     contributions: "Drums, Percussion"
    },
    {
     album: "Happy Country Hits",
     albumLink: "https://www.allmusic.com/album/happy-country-hits-mw0003859383",
     contributions: "Drums"
    },
    {
     album: "Happy Country Christmas",
     albumLink: "https://www.allmusic.com/album/happy-country-christmas-mw0003714900",
     contributions: "Drums"
    },
    {
     album: "Greatest Hits",
     albumLink: "https://www.allmusic.com/album/greatest-hits-mw0003550231",
     artist: "Parmalee",
     artistLink: "https://www.allmusic.com/artist/parmalee-mn0000130987",
     contributions: "Drums"
    },
    {
     album: "Grandes Temazos: Primavera",
     albumLink: "https://www.allmusic.com/album/grandes-temazos-primavera-mw0003873167",
     contributions: "Drums"
    },
    {
     album: "Gonna Be a Problem",
     albumLink: "https://www.allmusic.com/album/gonna-be-a-problem-mw0003684835",
     artist: "Everette",
     artistLink: "https://www.allmusic.com/artist/everette-mn0002161633",
     contributions: "Drums, Percussion"
    },
    {
     album: "Going Going Gone",
     albumLink: "https://www.allmusic.com/album/going-going-gone-mw0003372960",
     artist: "Ryan Griffin",
     artistLink: "https://www.allmusic.com/artist/ryan-griffin-mn0003115097",
     contributions: "Drums"
    },
    {
     album: "Glasshouse Children",
     albumLink: "https://www.allmusic.com/album/glasshouse-children-mw0003595956",
     artist: "Sam Williams",
     artistLink: "https://www.allmusic.com/artist/sam-williams-mn0003339197",
     contributions: "Drums"
    },
    {
     album: "Giving Tuesday, 2021",
     albumLink: "https://www.allmusic.com/album/giving-tuesday-2021-mw0003721413",
     contributions: "Drums"
    },
    {
     album: "Give You My All",
     albumLink: "https://www.allmusic.com/album/give-you-my-all-mw0003844581",
     artist: "Jon Langston",
     artistLink: "https://www.allmusic.com/artist/jon-langston-mn0003397785",
     contributions: "Drums"
    },
    {
     album: "Girl Power",
     albumLink: "https://www.allmusic.com/album/girl-power-mw0003596259",
     contributions: "Drums, Percussion, Programming"
    },
    {
     album: "Girl Go",
     albumLink: "https://www.allmusic.com/album/girl-go-mw0003507166",
     artist: "Chrissy Metz",
     artistLink: "https://www.allmusic.com/artist/chrissy-metz-mn0003677503",
     contributions: "Drums"
    },
    {
     album: "Giddy Up [2023]",
     albumLink: "https://www.allmusic.com/album/giddy-up-2023--mw0003948013",
     contributions: "Drums"
    },
    {
     album: "Getcha Some",
     albumLink: "https://www.allmusic.com/album/getcha-some-mw0003778330",
     artist: "Frank Ray",
     artistLink: "https://www.allmusic.com/artist/frank-ray-mn0003023555",
     contributions: "Drums, Percussion"
    },
    {
     album: "Get There First",
     albumLink: "https://www.allmusic.com/album/get-there-first-mw0003928105",
     artist: "Austin Snell",
     artistLink: "https://www.allmusic.com/artist/austin-snell-mn0004383342",
     contributions: "Drums"
    },
    {
     album: "Gentleman",
     albumLink: "https://www.allmusic.com/album/gentleman-mw0003661851",
     artist: "Tiera Kennedy",
     artistLink: "https://www.allmusic.com/artist/tiera-kennedy-mn0004188770",
     contributions: "Drums"
    },
    {
     album: "Game Day Country Hits 2022",
     albumLink: "https://www.allmusic.com/album/game-day-country-hits-2022-mw0003692755",
     contributions: "Drums"
    },
    {
     album: "For Your Quarantine",
     albumLink: "https://www.allmusic.com/album/for-your-quarantine-mw0003370357",
     contributions: "Drums"
    },
    {
     album: "Football Tailgate Party",
     albumLink: "https://www.allmusic.com/album/football-tailgate-party-mw0003572948",
     contributions: "Drums"
    },
    {
     album: "Football Party Country",
     albumLink: "https://www.allmusic.com/album/football-party-country-mw0003824153",
     contributions: "Drums"
    },
    {
     album: "Flirting by the Fire Pit",
     albumLink: "https://www.allmusic.com/album/flirting-by-the-fire-pit-mw0003555954",
     contributions: "Drums, Percussion, Recording"
    },
    {
     album: "Flip Side",
     albumLink: "https://www.allmusic.com/album/flip-side-mw0002666088",
     artist: "Veronica Ballestrini",
     artistLink: "https://www.allmusic.com/artist/veronica-ballestrini-mn0001493178",
     contributions: "Drums"
    },
    {
     album: "Flatlands",
     albumLink: "https://www.allmusic.com/album/flatlands-mw0002811471",
     artist: "Ryan Culwell",
     artistLink: "https://www.allmusic.com/artist/ryan-culwell-mn0002082694",
     contributions: "Drums, Percussion"
    },
    {
     album: "Female Country",
     albumLink: "https://www.allmusic.com/album/female-country-mw0003805427",
     contributions: "Drums"
    },
    {
     album: "Feel Good",
     albumLink: "https://www.allmusic.com/album/feel-good-mw0003426823",
     artist: "Chrissy Metz",
     artistLink: "https://www.allmusic.com/artist/chrissy-metz-mn0003677503",
     contributions: "Drums"
    },
    {
     album: "Father's Day Country 2022",
     albumLink: "https://www.allmusic.com/album/fathers-day-country-2022-mw0003811128",
     contributions: "Drums"
    },
    {
     album: "Father's Day Country 2021",
     albumLink: "https://www.allmusic.com/album/fathers-day-country-2021-mw0003571033",
     contributions: "Drums"
    },
    {
     album: "Father's Day Country",
     albumLink: "https://www.allmusic.com/album/fathers-day-country-mw0003820265",
     contributions: "Drums"
    },
    {
     album: "Father's Day 2021 [Universal] [#1]",
     albumLink: "https://www.allmusic.com/album/fathers-day-2021-universal-1--mw0003566648",
     contributions: "Drums"
    },
    {
     album: "Family Friendly Christmas",
     albumLink: "https://www.allmusic.com/album/family-friendly-christmas-mw0003657727",
     contributions: "Drums"
    },
    {
     album: "Fall Country",
     albumLink: "https://www.allmusic.com/album/fall-country-mw0003648528",
     contributions: "Drums, Percussion"
    },
    {
     album: "Every Night Every Morning",
     albumLink: "https://www.allmusic.com/album/every-night-every-morning-mw0003767793",
     artist: "Maddie &amp; Tae",
     artistLink: "https://www.allmusic.com/artist/maddie-tae-mn0003282505",
     contributions: "Drums"
    },
    {
     album: "Estilo Country",
     albumLink: "https://www.allmusic.com/album/estilo-country-mw0003525960",
     contributions: "Drums, Percussion, Recording"
    },
    {
     album: "Easter Country",
     albumLink: "https://www.allmusic.com/album/easter-country-mw0003724434",
     contributions: "Drums"
    },
    {
     album: "Drink a Little Whiskey Down",
     albumLink: "https://www.allmusic.com/album/drink-a-little-whiskey-down-mw0003494472",
     artist: "Luke Bryan",
     artistLink: "https://www.allmusic.com/artist/luke-bryan-mn0000502198",
     contributions: "Drums"
    },
    {
     album: "Down Home",
     albumLink: "https://www.allmusic.com/album/down-home-mw0003673143",
     artist: "Jimmie Allen",
     artistLink: "https://www.allmusic.com/artist/jimmie-allen-mn0003672595",
     contributions: "Drums, Programmer"
    },
    {
     album: "Down",
     albumLink: "https://www.allmusic.com/album/down-mw0003411550",
     artist: "Ryan Griffin",
     artistLink: "https://www.allmusic.com/artist/ryan-griffin-mn0003115097",
     contributions: "Drums"
    },
    {
     album: "Dosis de Country",
     albumLink: "https://www.allmusic.com/album/dosis-de-country-mw0003892311",
     contributions: "Drums"
    },
    {
     album: "Dock Rock",
     albumLink: "https://www.allmusic.com/album/dock-rock-mw0003376130",
     artist: "The Washboard Union",
     artistLink: "https://www.allmusic.com/artist/the-washboard-union-mn0002980203",
     contributions: "Drums, Percussion"
    },
    {
     album: "Details",
     albumLink: "https://www.allmusic.com/album/details-mw0003301693",
     artist: "Billy Currington",
     artistLink: "https://www.allmusic.com/artist/billy-currington-mn0000081739",
     contributions: "Drums, Recording"
    },
    {
     album: "Critic",
     albumLink: "https://www.allmusic.com/album/critic-mw0003725796",
     artist: "Avery Anna",
     artistLink: "https://www.allmusic.com/artist/avery-anna-mn0004113465",
     contributions: "Drums"
    },
    {
     album: "Country'd Look Good on You",
     albumLink: "https://www.allmusic.com/album/countryd-look-good-on-you-mw0003550346",
     artist: "Frank Ray",
     artistLink: "https://www.allmusic.com/artist/frank-ray-mn0003023555",
     contributions: "Drums, Percussion"
    },
    {
     album: "Country Tailgate",
     albumLink: "https://www.allmusic.com/album/country-tailgate-mw0003733830",
     contributions: "Drums, Percussion"
    },
    {
     album: "Country Sunday: A Day of Rest",
     albumLink: "https://www.allmusic.com/album/country-sunday-a-day-of-rest-mw0003525024",
     contributions: "Drums"
    },
    {
     album: "Country Summer 2022",
     albumLink: "https://www.allmusic.com/album/country-summer-2022-mw0003813469",
     contributions: "Drums"
    },
    {
     album: "Country State of Mind",
     albumLink: "https://www.allmusic.com/album/country-state-of-mind-mw0003403974",
     artist: "Chris Janson / Josh Turner",
     contributions: "Drums, Percussion, Recording"
    },
    {
     album: "Country Romântico",
     albumLink: "https://www.allmusic.com/album/country-rom%C3%A2ntico-mw0003431845",
     contributions: "Drums"
    },
    {
     album: "Country Romance [2022]",
     albumLink: "https://www.allmusic.com/album/country-romance-2022--mw0003720395",
     contributions: "Drums"
    },
    {
     album: "Country Rock Hits",
     albumLink: "https://www.allmusic.com/album/country-rock-hits-mw0003735309",
     contributions: "Drums"
    },
    {
     album: "Country Prom",
     albumLink: "https://www.allmusic.com/album/country-prom-mw0003616131",
     contributions: "Drums, Percussion"
    },
    {
     album: "Country Party Songs [2021]",
     albumLink: "https://www.allmusic.com/album/country-party-songs-2021--mw0003567214",
     contributions: "Drums"
    },
    {
     album: "Country Party Music [2020]",
     albumLink: "https://www.allmusic.com/album/country-party-music-2020--mw0003398143",
     contributions: "Drums, Percussion"
    },
    {
     album: "Country Nights",
     albumLink: "https://www.allmusic.com/album/country-nights-mw0003448116",
     contributions: "Drums, Percussion"
    },
    {
     album: "Country Music Summer",
     albumLink: "https://www.allmusic.com/album/country-music-summer-mw0003847591",
     contributions: "Drums"
    },
    {
     album: "Country Music Month",
     albumLink: "https://www.allmusic.com/album/country-music-month-mw0003534038",
     contributions: "Drums, Programming"
    },
    {
     album: "Country Love Songs [Universal]",
     albumLink: "https://www.allmusic.com/album/country-love-songs-universal--mw0003480441",
     contributions: "Drums"
    },
    {
     album: "Country Love Songs [2022]",
     albumLink: "https://www.allmusic.com/album/country-love-songs-2022--mw0003720396",
     contributions: "Drums"
    },
    {
     album: "Country Love Songs [2022]",
     albumLink: "https://www.allmusic.com/album/country-love-songs-2022--mw0003950080",
     contributions: "Drums"
    },
    {
     album: "Country Ladies [2022]",
     albumLink: "https://www.allmusic.com/album/country-ladies-2022--mw0003724806",
     contributions: "Drums"
    },
    {
     album: "Country Indie Crossover",
     albumLink: "https://www.allmusic.com/album/country-indie-crossover-mw0003870737",
     contributions: "Drums, Percussion"
    },
    {
     album: "Country Hits 2022",
     albumLink: "https://www.allmusic.com/album/country-hits-2022-mw0003900778",
     contributions: "Drums"
    },
    {
     album: "Country Hits",
     albumLink: "https://www.allmusic.com/album/country-hits-mw0003471740",
     contributions: "Drums, Percussion"
    },
    {
     album: "Country Happy Hour",
     albumLink: "https://www.allmusic.com/album/country-happy-hour-mw0003555953",
     contributions: "Drums, Percussion"
    },
    {
     album: "Country Drinking",
     albumLink: "https://www.allmusic.com/album/country-drinking-mw0003533700",
     contributions: "Drums, Percussion"
    },
    {
     album: "Country Does",
     albumLink: "https://www.allmusic.com/album/country-does-mw0003480088",
     artist: "Luke Bryan",
     artistLink: "https://www.allmusic.com/artist/luke-bryan-mn0000502198",
     contributions: "Drums, Percussion"
    },
    {
     album: "Country Dad",
     albumLink: "https://www.allmusic.com/album/country-dad-mw0003764619",
     contributions: "Drums"
    },
    {
     album: "Country Cinco de Mayo, Vol. 2",
     albumLink: "https://www.allmusic.com/album/country-cinco-de-mayo-vol-2-mw0003523410",
     contributions: "Drums, Programming"
    },
    {
     album: "Country Christmas [2021]",
     albumLink: "https://www.allmusic.com/album/country-christmas-2021--mw0003710834",
     contributions: "Drums"
    },
    {
     album: "Country Christmas Music 2021",
     albumLink: "https://www.allmusic.com/album/country-christmas-music-2021-mw0003664457",
     contributions: "Drums"
    },
    {
     album: "Country Christmas Music",
     albumLink: "https://www.allmusic.com/album/country-christmas-music-mw0003710665",
     contributions: "Drums"
    },
    {
     album: "Country Christmas Greatest Hits, Vol. 4",
     albumLink: "https://www.allmusic.com/album/country-christmas-greatest-hits-vol-4-mw0003809169",
     contributions: "Drum, Drums"
    },
    {
     album: "Country Christmas Greatest Hits [2022]",
     albumLink: "https://www.allmusic.com/album/country-christmas-greatest-hits-2022--mw0003855949",
     contributions: "Drums"
    },
    {
     album: "Country Christmas Eve Service",
     albumLink: "https://www.allmusic.com/album/country-christmas-eve-service-mw0003762290",
     contributions: "Drums"
    },
    {
     album: "Country Christian",
     albumLink: "https://www.allmusic.com/album/country-christian-mw0003738104",
     contributions: "Drums"
    },
    {
     album: "Country Boy, I Love You",
     albumLink: "https://www.allmusic.com/album/country-boy-i-love-you-mw0003952437",
     contributions: "Drums"
    },
    {
     album: "Country Boat Bops",
     albumLink: "https://www.allmusic.com/album/country-boat-bops-mw0003547992",
     contributions: "Drums"
    },
    {
     album: "Country Beach Vibes",
     albumLink: "https://www.allmusic.com/album/country-beach-vibes-mw0003627865",
     contributions: "Drums, Percussion"
    },
    {
     album: "Country BBQ [July, 2021]",
     albumLink: "https://www.allmusic.com/album/country-bbq-july-2021--mw0003757695",
     contributions: "Drums"
    },
    {
     album: "Country BBQ",
     albumLink: "https://www.allmusic.com/album/country-bbq-mw0003555952",
     contributions: "Drums"
    },
    {
     album: "Country 2022",
     albumLink: "https://www.allmusic.com/album/country-2022-mw0003864305",
     contributions: "Drums"
    },
    {
     album: "Country 2021",
     albumLink: "https://www.allmusic.com/album/country-2021-mw0003864312",
     contributions: "Drums"
    },
    {
     album: "Country 2020-2021",
     albumLink: "https://www.allmusic.com/album/country-2020-2021-mw0003563229",
     contributions: "Drums, Percussion"
    },
    {
     album: "Cooking Music: Country",
     albumLink: "https://www.allmusic.com/album/cooking-music-country-mw0003757694",
     contributions: "Drums"
    },
    {
     album: "Clean Country Music",
     albumLink: "https://www.allmusic.com/album/clean-country-music-mw0003567215",
     contributions: "Drums"
    },
    {
     album: "Classic Country Hits [2022]",
     albumLink: "https://www.allmusic.com/album/classic-country-hits-2022--mw0003899462",
     contributions: "Drums, Tambourine"
    },
    {
     album: "Christmas Country Songs 2022",
     albumLink: "https://www.allmusic.com/album/christmas-country-songs-2022-mw0003931270",
     contributions: "Drums"
    },
    {
     album: "Christmas Country Music",
     albumLink: "https://www.allmusic.com/album/christmas-country-music-mw0003664950",
     contributions: "Drums"
    },
    {
     album: "Christmas All Over the World",
     albumLink: "https://www.allmusic.com/album/christmas-all-over-the-world-mw0003611056",
     artist: "Tim McGraw",
     artistLink: "https://www.allmusic.com/artist/tim-mcgraw-mn0000592954",
     contributions: "Percussion"
    },
    {
     album: "Carrying Your Love With Me",
     albumLink: "https://www.allmusic.com/album/carrying-your-love-with-me-mw0003598715",
     artist: "Parker McCollum",
     artistLink: "https://www.allmusic.com/artist/parker-mccollum-mn0003377460",
     contributions: "Drums, Percussion"
    },
    {
     album: "Can't Miss You Anymore",
     albumLink: "https://www.allmusic.com/album/cant-miss-you-anymore-mw0003613765",
     artist: "Avery Anna",
     artistLink: "https://www.allmusic.com/artist/avery-anna-mn0004113465",
     contributions: "Drums"
    },
    {
     album: "Can't Fool Your Own Blood",
     albumLink: "https://www.allmusic.com/album/cant-fool-your-own-blood-mw0003555248",
     artist: "Sam Williams",
     artistLink: "https://www.allmusic.com/artist/sam-williams-mn0003339197",
     contributions: "Drums"
    },
    {
     album: "Can't Even Get the Blues [Revived]",
     albumLink: "https://www.allmusic.com/album/cant-even-get-the-blues-revived--mw0003583620",
     artist: "Reba McEntire",
     artistLink: "https://www.allmusic.com/artist/reba-mcentire-mn0000409968",
     contributions: "Drums"
    },
    {
     album: "Can't Beat the View",
     albumLink: "https://www.allmusic.com/album/cant-beat-the-view-mw0003683632",
     artist: "Chris Buck Band",
     artistLink: "https://www.allmusic.com/artist/chris-buck-band-mn0003823431",
     contributions: "Drums"
    },
    {
     album: "Can We Make It Through December",
     albumLink: "https://www.allmusic.com/album/can-we-make-it-through-december-mw0003462580",
     contributions: "Drums"
    },
    {
     album: "Build Me a Daddy",
     albumLink: "https://www.allmusic.com/album/build-me-a-daddy-mw0003404716",
     artist: "Luke Bryan",
     artistLink: "https://www.allmusic.com/artist/luke-bryan-mn0000502198",
     contributions: "Drums"
    },
    {
     album: "Brother",
     albumLink: "https://www.allmusic.com/album/brother-mw0003381914",
     artist: "Tyler Braden",
     artistLink: "https://www.allmusic.com/artist/tyler-braden-mn0003934030",
     contributions: "Drums, Percussion"
    },
    {
     album: "Broken Heart Country",
     albumLink: "https://www.allmusic.com/album/broken-heart-country-mw0003492246",
     contributions: "Drums, Percussion"
    },
    {
     album: "Bridesmaids",
     albumLink: "https://www.allmusic.com/album/bridesmaids-mw0003832645",
     artist: "Kylie Morgan",
     artistLink: "https://www.allmusic.com/artist/kylie-morgan-mn0003368858",
     contributions: "Drums"
    },
    {
     album: "Boys Like You",
     albumLink: "https://www.allmusic.com/album/boys-like-you-mw0003323419",
     artist: "Taylor Lynn",
     artistLink: "https://www.allmusic.com/artist/taylor-lynn-mn0003879660",
     contributions: "Drums, Percussion"
    },
    {
     album: "Blue Christmas",
     albumLink: "https://www.allmusic.com/album/blue-christmas-mw0003868776",
     artist: "Tiera Kennedy",
     artistLink: "https://www.allmusic.com/artist/tiera-kennedy-mn0004188770",
     contributions: "Drums, Engineer"
    },
    {
     album: "Black Motorcycle",
     albumLink: "https://www.allmusic.com/album/black-motorcycle-mw0003436431",
     artist: "Kassi Ashton",
     artistLink: "https://www.allmusic.com/artist/kassi-ashton-mn0003661555",
     contributions: "Drums, Percussion"
    },
    {
     album: "Big in a Small Town",
     albumLink: "https://www.allmusic.com/album/big-in-a-small-town-mw0003574978",
     artist: "Jimmie Allen",
     artistLink: "https://www.allmusic.com/artist/jimmie-allen-mn0003672595",
     contributions: "Bass, Drums, Percussion, Programmer"
    },
    {
     album: "Big Machine: Big Songs of 2021",
     albumLink: "https://www.allmusic.com/album/big-machine-big-songs-of-2021-mw0003880921",
     contributions: "Drums"
    },
    {
     album: "Big Machine: Big Songs of 2020",
     albumLink: "https://www.allmusic.com/album/big-machine-big-songs-of-2020-mw0003454936",
     contributions: "Drums"
    },
    {
     album: "Big Machine: Big Songs of 2018",
     albumLink: "https://www.allmusic.com/album/big-machine-big-songs-of-2018-mw0003242861",
     contributions: "Drums"
    },
    {
     album: "Big Machine Undercover",
     albumLink: "https://www.allmusic.com/album/big-machine-undercover-mw0003152689",
     contributions: "Drums"
    },
    {
     album: "Big Machine #1's, Vol. 5",
     albumLink: "https://www.allmusic.com/album/big-machine-1s-vol-5-mw0003860337",
     contributions: "Drums"
    },
    {
     album: "Best of Country Music 2022",
     albumLink: "https://www.allmusic.com/album/best-of-country-music-2022-mw0003940563",
     contributions: "Drums, Percussion, Tambourine"
    },
    {
     album: "Best of 2021: Summer Lounge",
     albumLink: "https://www.allmusic.com/album/best-of-2021-summer-lounge-mw0003900796",
     contributions: "Drums"
    },
    {
     album: "Best of 2021 Country",
     albumLink: "https://www.allmusic.com/album/best-of-2021-country-mw0003707329",
     contributions: "Drums, Percussion"
    },
    {
     album: "Best of 2020 Country",
     albumLink: "https://www.allmusic.com/album/best-of-2020-country-mw0003465882",
     contributions: "Drums"
    },
    {
     album: "Best Tunes of 2022",
     albumLink: "https://www.allmusic.com/album/best-tunes-of-2022-mw0003947310",
     contributions: "Drums"
    },
    {
     album: "Best Country of the Decade: 2011-2020",
     albumLink: "https://www.allmusic.com/album/best-country-of-the-decade-2011-2020-mw0003461970",
     contributions: "Drums"
    },
    {
     album: "Best Country of the Decade: 2010-2019",
     albumLink: "https://www.allmusic.com/album/best-country-of-the-decade-2010-2019-mw0003892309",
     contributions: "Drums, Percussion"
    },
    {
     album: "Best Country of 2019",
     albumLink: "https://www.allmusic.com/album/best-country-of-2019-mw0003385819",
     contributions: "Drums, Recording"
    },
    {
     album: "Best Country Valentine's Day [2022]",
     albumLink: "https://www.allmusic.com/album/best-country-valentines-day-2022--mw0003691006",
     contributions: "Programming"
    },
    {
     album: "Best Country Rock 2022",
     albumLink: "https://www.allmusic.com/album/best-country-rock-2022-mw0003930181",
     contributions: "Drums, Programming"
    },
    {
     album: "Best Country Hits",
     albumLink: "https://www.allmusic.com/album/best-country-hits-mw0003570680",
     contributions: "Drums"
    },
    {
     album: "Best Country Christmas [2021]",
     albumLink: "https://www.allmusic.com/album/best-country-christmas-2021--mw0003680372",
     contributions: "Percussion"
    },
    {
     album: "Best Country Christmas 2022",
     albumLink: "https://www.allmusic.com/album/best-country-christmas-2022-mw0003895992",
     contributions: "Drums, Engineer, Percussion"
    },
    {
     album: "Backstory",
     albumLink: "https://www.allmusic.com/album/backstory-mw0003572304",
     artist: "Runaway June",
     artistLink: "https://www.allmusic.com/artist/runaway-june-mn0003520376",
     contributions: "Drums, Engineer"
    },
    {
     album: "BBQ Party 2022",
     albumLink: "https://www.allmusic.com/album/bbq-party-2022-mw0003860095",
     contributions: "Drums"
    },
    {
     album: "Answers",
     albumLink: "https://www.allmusic.com/album/answers-mw0003968045",
     artist: "Chris Buck Band",
     artistLink: "https://www.allmusic.com/artist/chris-buck-band-mn0003823431",
     contributions: "Drums"
    },
    {
     album: "American Spirit: 4th of July Country",
     albumLink: "https://www.allmusic.com/album/american-spirit-4th-of-july-country-mw0003851683",
     contributions: "Drums"
    },
    {
     album: "American Spirit [Big Machine] [2021]",
     albumLink: "https://www.allmusic.com/album/american-spirit-big-machine-2021--mw0003590882",
     contributions: "Drums"
    },
    {
     album: "American Spirit [Big Machine] [2020]",
     albumLink: "https://www.allmusic.com/album/american-spirit-big-machine-2020--mw0003595117",
     contributions: "Drums"
    },
    {
     album: "Amantes del Country",
     albumLink: "https://www.allmusic.com/album/amantes-del-country-mw0003567211",
     contributions: "Drums"
    },
    {
     album: "All I Want For Christmas!",
     albumLink: "https://www.allmusic.com/album/all-i-want-for-christmas%21-mw0003685140",
     contributions: "Drums"
    },
    {
     album: "Alabama Nights",
     albumLink: "https://www.allmusic.com/album/alabama-nights-mw0003837713",
     artist: "Tiera Kennedy",
     artistLink: "https://www.allmusic.com/artist/tiera-kennedy-mn0004188770",
     contributions: "Drums"
    },
    {
     album: "Actress",
     albumLink: "https://www.allmusic.com/album/actress-mw0003590461",
     artist: "Chrissy Metz",
     artistLink: "https://www.allmusic.com/artist/chrissy-metz-mn0003677503",
     contributions: "Drums"
    },
    {
     album: "A Merry Modern Christmas",
     albumLink: "https://www.allmusic.com/album/a-merry-modern-christmas-mw0003855052",
     contributions: "Drums"
    },
    {
     album: "9/11: Never Forget [2022 Version]",
     albumLink: "https://www.allmusic.com/album/9-11-never-forget-2022-version--mw0003835364",
     contributions: "Drums"
    },
    {
     album: "9/11: Never Forget",
     albumLink: "https://www.allmusic.com/album/9-11-never-forget-mw0003594829",
     contributions: "Drums"
    },
    {
     album: "4th of July: Country Edition",
     albumLink: "https://www.allmusic.com/album/4th-of-july-country-edition-mw0003950544",
     contributions: "Drums"
    },
    {
     album: "2023 Best Country Nominees",
     albumLink: "https://www.allmusic.com/album/2023-best-country-nominees-mw0003950081",
     contributions: "Drums"
    },
    {
     album: "2022 Prime Country Music Awards",
     albumLink: "https://www.allmusic.com/album/2022-prime-country-music-awards-mw0003773281",
     contributions: "Drums"
    }
   ]

   export default credits;