import { Col, Row, Table, Typography } from "antd";
import styled from "styled-components";
import FadeIn from "../components/FadeIn";
import credits from "../data/credits";
import SectionContainer from "./Container";

const CreditsTable = styled(Table)`

  .ant-table {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background: #00000080 !important;
  }

  .ant-table-cell {
    background: transparent !important;
    border-bottom: none !important;
  }

  .ant-spin-container {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
  }

  

  .ant-pagination-total-text {
    color: black;
  }

  .ant-table-thead {
    background-color: transparent !important;
  }

  .ant-table-thead > tr > th {
    color: white !important;
  }

  .ant-table-thead > tr > th::before {
    background-color: transparent !important;
  }

  .ant-table-column-sorter{
    color:white !important;
  }

  .ant-table-thead > tr {
    border-width: 0px !important;
  }

  .ant-table-column-sorter-down.active {
    color: teal;
  }

  .ant-pagination-prev > button > span,
  .ant-pagination-next > button > span,
  .ant-pagination-item-ellipsis {
    color: teal !important;
  }

  .ant-pagination-item > a {
    color: #aaa;
  }

  .ant-pagination-item-active {
    font-size:14px;
    border-color:transparent;
    border-color: white;
    background-color:transparent;
    border-width: 1px;
  }

  .ant-pagination-item-active > a {
    color:white;
    // margin-top: -1px;
  
  }

  .ant-pagination-item-active:hover {
    border-color:white !important;

  }

  .ant-pagination-item:hover > a {
    color: white !important;
    // border-color:transparent !important;
  }

  
`;

const Credits = () => {
  return (
    <SectionContainer
      title="Credits"
      id="credits"
      bgColor="black"
      titleColor="white"
    >
      <FadeIn direction="bottom">
        <CreditsTable
          showSorterTooltip={false}
          scroll={{ x: 100 }}
          style={{
            backgroundColor: "transparent",
          }}
          size="small"
          dataSource={credits.filter((c) => c.album)}
          columns={[
            {
              key: 1,
              align: "center",
              title: "Year",
              dataIndex: "year",
              sorter: (a: any, b: any) => a.year - b.year,
              render: (year) => (
                <Typography.Text  ellipsis style={{ color: "white", fontWeight:'100' }}>
                  {year}
                </Typography.Text>
              ),
            },
            {
              key: 2,
              align: "center",
              title: "Album",
              dataIndex: "album",
              sorter: (a: any, b: any) => {
                a = (a.album || "").toString();
                b = (b.album || "").toString();
                return a.localeCompare(b);
              },
              //@ts-ignore
              render: (album, { albumLink }) => (
                <Typography.Text ellipsis>
                  <a
                    style={{ color: "teal", fontWeight:'400'  }}
                    href={albumLink}
                    target="_blank"
                  >
                    {album?.toString().replace("&amp;", "&")}
                  </a>
                </Typography.Text>
              ),
            },
            {
              key: 3,
              align: "center",
              title: "Artist",
              dataIndex: "artist",
              sorter: (a: any, b: any) => {
                a = (a.artist || "").toString();
                b = (b.artist || "").toString();
                return a.localeCompare(b);
              },
              //@ts-ignore
              render: (artist, { artistLink }) => (
                <Typography.Text strong ellipsis>
                  <a
                    style={{ color: "teal",  fontWeight:'400' }}
                    href={artistLink}
                    target="_blank"
                  >
                    {artist?.replace("&amp;", "&")}
                  </a>
                </Typography.Text>
              ),
            },
            {
              key: 4,
              align: "center",
              title: "Contributions",
              dataIndex: "contributions",
              render: (contributions: string) => (
                <Typography.Text ellipsis style={{color:'white'}}>
                  <small>{contributions}</small>
                </Typography.Text>
              ),
            },
          ]}
          pagination={{
            size: "small",
            total: credits.length,
            pageSize: 25,
            showSizeChanger: false,
            style: {
              width: "100%",
              height: "100%",
              overflowY: "scroll",
              paddingRight: 17,
              color: "white !important",
              marginTop: 0,
              padding: "12px 0px 12px 0px",
              borderTop: "none",
              background: "transparent",
              boxSizing:'content-box'
            },
          }}
        />
      </FadeIn>
    </SectionContainer>
  );
};

export default Credits;
